import React, { Component } from "react";
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import { Switch, Route, Redirect } from "react-router-dom";
import Index from "./com/index";
import Print from "./com/content/print/index";
import Login from './views/Login';
class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    console.log('log',sessionStorage.getItem('loggedin'))
    if(!sessionStorage.getItem('loggedin') && window.location.pathname!="/login"){
     window.location.href="/login"
   }

    return (
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="/print" component={Print} />

            <Route path="/login" component={Login} />
            <Route path="/*" component={Index} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default withRouter(App);
