import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { browserHistory } from "react-router";

import { library } from "@fortawesome/fontawesome-svg-core";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Links from "../../json/links.json";

const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

class Left extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPath: "dashboard",
      subSections: [],
      subTitle: "Dashboard",
    };
    this._rerender();
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log(this.props.match.params.path);
    //console.log(prevState.selectedPath);
    if (this.props.match.params.path !== this.state.selectedPath) {
      this._rerender();
    }
  }

  _rerender = () => {
    //console.log(this.props.match.params.path);
    var path = this.props.match.params.path
      ? this.props.match.params.path
      : "/";
    var find = Links.find((link) => link.to.toLowerCase() == "/" + path);
    //console.log(typeof path, path, find);
    if (typeof path === "string" && find) {
      this.setState(
        {
          selectedPath: path,
          subSections: find.subSections,
          subTitle: find.name,
        },
        function () {
          //console.log(this.state.subSections);
        }
      );
    } else {
      //console.log(123);
   
    }
  };

  render() {
    return (
      <div>
        <div className="components-menu">
          <ul className="nav components-menu_nav">
            {Links.map((link, key) => {
              return (
                <li className={"nav-item" + " " + link.className} key={key}>
                  <FontAwesomeIcon icon={link.icon} />
                  <Link className="nav-link" to={link.to ? link.to : ""}>
                    {link.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>

        {this.state.selectedPath !== "" && this.state.subSections.length > 0 ? (
          <div className="components-sub_menu">
            <div className="sub-header">
              <h3>{this.state.subTitle}</h3>
            </div>
            <ul className="nav nav-pills flex-column">
              {this.state.subSections.map((section, key) => {
                return (
                  <li className="nav-item" key={key}>
                    <Link
                      className={"nav-link" + " " + section.className}
                      to={section.to ? section.to : ""}
                    >
                      {section.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default Left;
