import React, { Component } from "react";
import {
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Tabs,
  Tab,
  ListGroup,
} from "react-bootstrap";

import { connect } from "react-redux";
import ColorPicker from "../../../../com/lib/plugins/color-picker/ColorPicker";
import { updateItems } from "../../../../redux/actions/TemplateActions";

import {
  changeProprty,
  changeProprtyM,
  companyPath,
  createTableElement,
} from "../../../../com/lib/global/helpers";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(faLightbulb);

class Items extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attributes: props.attributes,
      selectedAttributePath: props.selectedAttributePath,
    };
  }

  _selectAttributeSection = (key) => {
    var properties = {
      ...this.props.properties,
      defenition: key,
    };
    //console.log(properties);
    this.props.updateItems(properties);
  };

  _addItem = (e, key) => {
    //console.log("key", companyPath())
   // console.log(e)
    var add = e.target.checked;
    var ele = undefined;
    if (add) ele = createTableElement(companyPath() + "." + key);
    var properties = {
      ...this.props.properties,
      items: {
        ...this.props.properties.items,
        [key]: ele,
      },
    };

    this.props.updateItems(JSON.parse(JSON.stringify(properties)));
  };

  _renderItems = () => {
    return (

    <> 
    {
      this.props.properties.defenition &&
      Object.keys(
        this.state.attributes[this.props.properties.defenition].Properties
      ).filter(p => p!=="CustomArray")
      .map((key) => {
        var obj =
          this.state.attributes[this.props.properties.defenition].Properties[
            key
          ];

        var found = this.props.properties.items[key];

        return (
          <ListGroup.Item
            key={key}
            className={"justify-content-between"}
            style={{ cursor: "pointer" }}
          >
            <div>
              <h6 style={{ margin: "3px 0" }}>{key}</h6>
              {found && (
                <Row className="mt-2">
                  <Col sm={4} className={"pe-1 ps-1"}>
                    <InputGroup>
                      <FormControl
                        type="number"
                        value={
                          this.props.properties.items[key] &&
                          this.props.properties.items[key].width
                        }
                        onChange={(e) =>
                          changeProprtyM(
                            "items." + key + ".width",
                            e.target.value,
                            this.props,
                            this.props.updateItems
                          )
                        }
                      />
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                  </Col>
                  <Col sm={4}>
                    <InputGroup>
                      <FormControl
                        value={
                          this.props.properties.items[key] &&
                          this.props.properties.items[key].header
                        }
                        onChange={(e) =>
                          changeProprtyM(
                            "items." + key + ".header",
                            e.target.value,
                            this.props,
                            this.props.updateItems
                          )
                        }
                      />
                    </InputGroup>
                  </Col>
                  <Col sm={4}>
                    <InputGroup>
                      <FormControl
                        value={
                          this.props.properties.items[key] &&
                          this.props.properties.items[key].stringFormat
                        }
                        onChange={(e) =>
                          changeProprtyM(
                            "items." + key + ".stringFormat",
                            e.target.value,
                            this.props,
                            this.props.updateItems
                          )
                        }
                      />
                    </InputGroup>
                  </Col>
                </Row>
              )}
              <Form.Group
                controlId="forPrintHeaderEveryPage"
                style={{
                  position: "absolute",
                  top: "7px",
                  right: "5px",
                }}
              >
                <Form.Check
                  type="checkbox"
                  className="fw-bold"
                  onChange={(e) => this._addItem(e, key)}
                  checked={this.props.properties && found}
                />
              </Form.Group>
              <small className={"small"}></small>
            </div>
          </ListGroup.Item>
        );
      })
    }
    {
      this.props.properties.defenition &&
      this.state.attributes[this.props.properties.defenition].Properties.CustomArray && 
      Object.keys(this.state.attributes[this.props.properties.defenition].Properties.CustomArray).map(key => {
        var found = this.props.properties.items[key];
        return (
          <ListGroup.Item
            key={key}
            className={"justify-content-between"}
            style={{ cursor: "pointer" }}
          >
            <div>
              <h6 style={{ margin: "3px 0" }}>{key}</h6>
              {found && (
                <Row className="mt-2">
                  <Col sm={4} className={"pe-1 ps-1"}>
                    <InputGroup>
                      <FormControl
                        type="number"
                        value={
                          this.props.properties.items[key] &&
                          this.props.properties.items[key].width
                        }
                        onChange={(e) =>
                          changeProprtyM(
                            "items." + key + ".width",
                            e.target.value,
                            this.props,
                            this.props.updateItems
                          )
                        }
                      />
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                  </Col>
                  <Col sm={4}>
                    <InputGroup>
                      <FormControl
                        value={
                          this.props.properties.items[key] &&
                          this.props.properties.items[key].header
                        }
                        onChange={(e) =>
                          changeProprtyM(
                            "items." + key + ".header",
                            e.target.value,
                            this.props,
                            this.props.updateItems
                          )
                        }
                      />
                    </InputGroup>
                  </Col>
                  <Col sm={4}>
                    <InputGroup>
                      <FormControl
                        value={
                          this.props.properties.items[key] &&
                          this.props.properties.items[key].stringFormat
                        }
                        onChange={(e) =>
                          changeProprtyM(
                            "items." + key + ".stringFormat",
                            e.target.value,
                            this.props,
                            this.props.updateItems
                          )
                        }
                      />
                    </InputGroup>
                  </Col>
                </Row>
              )}
              <Form.Group
                controlId="forPrintHeaderEveryPage"
                style={{
                  position: "absolute",
                  top: "7px",
                  right: "5px",
                }}
              >
                <Form.Check
                  type="checkbox"
                  className="fw-bold"
                  onChange={(e) => this._addItem(e, key)}
                  checked={this.props.properties && found}
                />
              </Form.Group>
              <small className={"small"}></small>
            </div>
          </ListGroup.Item>
        )
      })
    }
    </>
    );
  };

  render() {
    return (
      <>
        <div className="column-header">
          <span className="font-large">Items Properties</span>
          <Button
            variant="link ml-2"
            className="text-dark text-decoration-none"
            onClick={(e) => {
              this.props.showToolTips(true);
            }}
          >
            <FontAwesomeIcon icon="lightbulb" className="me-2" />
            Tool Tips
          </Button>
        </div>
        <div className="column-body">
          {this.props.properties ? (
            <Form>
              <Tabs id="controlled-tab">
                <Tab eventKey="labels" title="Lables">
                  <Row>
                    <Col>
                      <Form.Group className="mt-3">
                        <Form.Label>Item List</Form.Label>

                        <ListGroup>
                          {Object.keys(this.state.attributes).map((key) => {
                            if (
                              key==='EposSalesFieldsDefintion'
                              // this.state.attributes[key].DataType === "List"
                            ) {
                              return (
                                <ListGroup.Item
                                  key={key}
                                  onClick={(e) =>
                                    this._selectAttributeSection(key)
                                  }
                                  className={
                                    "d-flex justify-content-between lh-condensed " +
                                    (this.props.properties.defenition === key
                                      ? "bg-primary text-white"
                                      : "bg-white")
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <div>
                                    <h6 className="my-0 tmplate-multiline-text">{key}</h6>
                                    <small
                                      className={
                                        "small " +
                                        (this.props.properties.defenition ===
                                        key
                                          ? "bg-primary text-white"
                                          : "bg-white")
                                      }
                                    >
                                      {Object.keys(
                                        this.state.attributes[key].Properties
                                      ).length + " inherited properties"}
                                    </small>
                                  </div>
                                </ListGroup.Item>
                              );
                            }
                          })}
                        </ListGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Display List</Form.Label>
                        <Row>
                          <Col sm={4} className={"text-center"}>
                            <Form.Label>Width</Form.Label>
                          </Col>
                          <Col sm={4} className={"text-center"}>
                            <Form.Label>Header</Form.Label>
                          </Col>
                          <Col sm={4} className={"text-center"}>
                            <Form.Label>Format</Form.Label>
                          </Col>
                        </Row>
                        <ListGroup>{this._renderItems()}</ListGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="layout" title="Layout">
                  <Row>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label className="fw-bold mt-3">
                          Border Colour
                        </Form.Label>
                        <InputGroup
                          style={{ position: "inherit" }}
                        ></InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label className="fw-bold mt-3">
                          Border Colour
                        </Form.Label>
                        <InputGroup style={{ position: "inherit" }}>
                          <FormControl
                            value={this.props.properties.layout.tableBorder}
                            readOnly
                          />
                          <InputGroup.Append>
                            <ColorPicker
                              {...this.props}
                              color={this.props.properties.layout.tableBorder}
                              callbackFromParents={(e) => {
                                changeProprtyM(
                                  "layout.tableBorder",
                                  e.hex,
                                  this.props,
                                  this.props.updateItems
                                );
                              }}
                            />
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group controlId="formMargins">
                        <Form.Label className="fw-bold mt-2">
                          Table Header
                        </Form.Label>
                        <Row>
                          <Col sm={6}>
                            <Form.Group>
                              <Form.Label>Background Colour</Form.Label>
                              <InputGroup style={{ position: "inherit" }}>
                                <FormControl
                                  value={
                                    this.props.properties.layout.tableHeader
                                      .backgroundColor
                                  }
                                  readOnly
                                />
                                <InputGroup.Append>
                                  <ColorPicker
                                    {...this.props}
                                    color={
                                      this.props.properties.layout.tableHeader
                                        .backgroundColor
                                    }
                                    callbackFromParents={(e) => {
                                      changeProprtyM(
                                        "layout.tableHeader.backgroundColor",
                                        e.hex,
                                        this.props,
                                        this.props.updateItems
                                      );
                                    }}
                                  />
                                </InputGroup.Append>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group controlId="forFontSize">
                              <Form.Label>Font Size</Form.Label>
                              <InputGroup>
                                <FormControl
                                  value={
                                    this.props.properties.layout.tableHeader
                                      .fontSize
                                  }
                                  onChange={(e) =>
                                    changeProprtyM(
                                      "layout.tableHeader.fontSize",
                                      e.target.value,
                                      this.props,
                                      this.props.updateItems
                                    )
                                  }
                                  type="number"
                                />
                                <InputGroup.Text>pt</InputGroup.Text>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>

                          <Col sm={6}>
                            <Form.Group>
                              <Form.Label>Font </Form.Label>
                              <select
                                  className="form-select form-control"
                                  aria-label="Default select"
                                  onChange={(e) =>
                                    changeProprtyM(
                                      "layout.tableHeader.fontFamily",
                                      e.target.value,
                                      this.props,
                                      this.props.updateItems
                                    )
                                  }
                                  value={
                                    this.props.properties.layout.tableHeader.fontFamily?this.props.properties.layout.tableHeader.fontFamily:'Arial'
                                  }
                                >
                                  <option value="Arial">Arial</option>
                                  <option value="Times New Roman">Times New Roman</option>
                                  <option value="Verdana">Verdana</option>
                                  <option value="Georgia">Georgia</option>
                                  <option value="Open Sans">Open Sans</option>
                                  <option value="Roboto">Roboto</option>
                                  <option value="Calibri">Calibri</option>
                                </select>
                            </Form.Group>
                          </Col>

                          <Col sm={6}>
                            <Form.Group>
                              <Form.Label>Font Colour</Form.Label>
                              <InputGroup style={{ position: "inherit" }}>
                                <FormControl
                                  value={
                                    this.props.properties.layout.tableHeader
                                      .fontColor
                                  }
                                  readOnly
                                />
                                <InputGroup.Append>
                                  <ColorPicker
                                    {...this.props}
                                    color={
                                      this.props.properties.layout.tableHeader
                                        .fontColor
                                    }
                                    callbackFromParents={(e) => {
                                      changeProprtyM(
                                        "layout.tableHeader.fontColor",
                                        e.hex,
                                        this.props,
                                        this.props.updateItems
                                      );
                                    }}
                                  />
                                </InputGroup.Append>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group controlId="formMargins">
                        <Form.Label className="fw-bold">Item Row</Form.Label>
                        <Row>
                          <Col sm={6}>
                            <Form.Group>
                              <Form.Label>Background Colour</Form.Label>
                              <InputGroup style={{ position: "inherit" }}>
                                <FormControl
                                  value={
                                    this.props.properties.layout.itemRow
                                      .backgroundColor
                                  }
                                  readOnly
                                />
                                <InputGroup.Append>
                                  <ColorPicker
                                    {...this.props}
                                    color={
                                      this.props.properties.layout.itemRow
                                        .backgroundColor
                                    }
                                    callbackFromParents={(e) => {
                                      changeProprtyM(
                                        "layout.itemRow.backgroundColor",
                                        e.hex,
                                        this.props,
                                        this.props.updateItems
                                      );
                                    }}
                                  />
                                </InputGroup.Append>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group controlId="forFontSize">
                              <Form.Label>Font Size</Form.Label>
                              <InputGroup>
                                <FormControl
                                  value={
                                    this.props.properties.layout.itemRow
                                      .fontSize
                                  }
                                  onChange={(e) =>
                                    changeProprtyM(
                                      "layout.itemRow.fontSize",
                                      e.target.value,
                                      this.props,
                                      this.props.updateItems
                                    )
                                  }
                                  type="number"
                                />
                                <InputGroup.Text>pt</InputGroup.Text>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>

                          <Col sm={6}>
                            <Form.Group>
                              <Form.Label>Font </Form.Label>
                              <select
                                  className="form-select form-control"
                                  aria-label="Default select"
                                  onChange={(e) =>
                                    changeProprtyM(
                                      "layout.itemRow.fontFamily",
                                      e.target.value,
                                      this.props,
                                      this.props.updateItems
                                    )
                                  }
                                  value={this.props.properties.layout.itemRow.fontFamily?this.props.properties.layout.itemRow.fontFamily:'Arial'}
                                >
                                  <option value="Arial">Arial</option>
                                  <option value="Times New Roman">Times New Roman</option>
                                  <option value="Verdana">Verdana</option>
                                  <option value="Georgia">Georgia</option>
                                  <option value="Open Sans">Open Sans</option>
                                  <option value="Roboto">Roboto</option>
                                  <option value="Calibri">Calibri</option>
                                </select>
                            </Form.Group>
                          </Col>

                          <Col sm={6}>
                            <Form.Group>
                              <Form.Label>Font Colour</Form.Label>
                              <InputGroup style={{ position: "inherit" }}>
                                <FormControl
                                  value={
                                    this.props.properties.layout.itemRow
                                      .fontColor
                                  }
                                  readOnly
                                />
                                <InputGroup.Append>
                                  <ColorPicker
                                    {...this.props}
                                    color={
                                      this.props.properties.layout.itemRow
                                        .fontColor
                                    }
                                    callbackFromParents={(e) => {
                                      changeProprtyM(
                                        "layout.itemRow.fontColor",
                                        e.hex,
                                        this.props,
                                        this.props.updateItems
                                      );
                                    }}
                                  />
                                </InputGroup.Append>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={12}>
                            <Form.Group controlId="forOrganizationLogo">
                              <Form.Check
                                type="checkbox"
                                label="Show Horizontal Lines"
                                className="fw-bold"
                                checked={
                                  this.props.properties?.layout?.itemRow?.showHorizontalLines || false
                                }
                                onChange={(e) =>
                                  changeProprtyM(
                                    "layout.itemRow.showHorizontalLines",
                                    e.target.checked,
                                    this.props,
                                    this.props.updateItems
                                  )
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6}>
                            <Form.Group>
                              <Form.Label>Horizontal Line Colour</Form.Label>
                              <InputGroup style={{ position: "inherit" }}>
                                <FormControl
                                  value={
                                    this.props.properties.layout.itemRow
                                      .horizontalLineColor
                                  }
                                  readOnly
                                />
                                <InputGroup.Append>
                                  <ColorPicker
                                    {...this.props}
                                    color={
                                      this.props.properties.layout.itemRow
                                        .horizontalLineColor
                                    }
                                    callbackFromParents={(e) => {
                                      changeProprtyM(
                                        "layout.itemRow.horizontalLineColor",
                                        e.hex,
                                        this.props,
                                        this.props.updateItems
                                      );
                                    }}
                                  />
                                </InputGroup.Append>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group controlId="forFontSize">
                              <Form.Label>Line Size</Form.Label>
                              <InputGroup>
                                <FormControl
                                  value={
                                    this.props.properties.layout.itemRow
                                      .horizontalLineSize
                                  }
                                  onChange={(e) =>
                                    changeProprtyM(
                                      "layout.itemRow.horizontalLineSize",
                                      e.target.value,
                                      this.props,
                                      this.props.updateItems
                                    )
                                  }
                                  type="number"
                                />
                                <InputGroup.Text>pt</InputGroup.Text>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={12}>
                            <Form.Group controlId="forVerticleLines">
                              <Form.Check
                                type="checkbox"
                                label="Show Verticle Lines"
                                className="fw-bold"
                                checked={
                                  this.props.properties?.layout.itemRow?.showVerticleLines|| false
                                }
                                onChange={(e) =>
                                  changeProprtyM(
                                    "layout.itemRow.showVerticleLines",
                                    e.target.checked,
                                    this.props,
                                    this.props.updateItems
                                  )
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6}>
                            <Form.Group>
                              <Form.Label>Verticle Line Colour</Form.Label>
                              <InputGroup style={{ position: "inherit" }}>
                                <FormControl
                                  value={
                                    this.props.properties.layout.itemRow
                                      .verticleLineColor
                                  }
                                  readOnly
                                />
                                <InputGroup.Append>
                                  <ColorPicker
                                    {...this.props}
                                    color={
                                      this.props.properties.layout.itemRow
                                        .verticleLineColor
                                    }
                                    callbackFromParents={(e) => {
                                      changeProprtyM(
                                        "layout.itemRow.verticleLineColor",
                                        e.hex,
                                        this.props,
                                        this.props.updateItems
                                      );
                                    }}
                                  />
                                </InputGroup.Append>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group controlId="forFontSize">
                              <Form.Label>Line Size</Form.Label>
                              <InputGroup>
                                <FormControl
                                  value={
                                    this.props.properties.layout.itemRow
                                      .verticleLineSize
                                  }
                                  onChange={(e) =>
                                    changeProprtyM(
                                      "layout.itemRow.verticleLineSize",
                                      e.target.value,
                                      this.props,
                                      this.props.updateItems
                                    )
                                  }
                                  type="number"
                                />
                                <InputGroup.Text>pt</InputGroup.Text>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </Row>
                        {/* <Row>
                          <Col sm={6}>
                          <Form.Group>
                              <Form.Label>Max Row Count</Form.Label>
                              <InputGroup style={{ position: "inherit" }}>
                                <FormControl
                                  value={ this.props.properties.layout.itemRow.maxRowCount || 20 }
                                  onChange={(e) =>
                                    changeProprtyM(
                                      "layout.itemRow.maxRowCount",
                                      e.target.value,
                                      this.props,
                                      this.props.updateItems
                                    )
                                  }
                                  type="number"
                                />
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </Row> */}
                        {/* <Row>
                          <Col sm={6}>
                          <Form.Group>
                              <Form.Label>Lines Per Row</Form.Label>
                              <InputGroup style={{ position: "inherit" }}>
                                <FormControl
                                  value={
                                    this.props.properties.layout.itemRow
                                      .linesPerRow
                                  }
                                  onChange={(e) =>
                                    changeProprtyM(
                                      "layout.itemRow.linesPerRow",
                                      e.target.value,
                                      this.props,
                                      this.props.updateItems
                                    )
                                  }
                                  type="number"
                                />
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </Row> */}
                      </Form.Group>
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </Form>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    properties: state.template.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateItems: (e) => dispatch(updateItems(e)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Items);
