import React from 'react'

export default function HTMLTotalTemplate(props) {
    const pageSettings = {
        'a4': {
            'width': 794,
            'height': 1123
        },
        'letter': {
            'width': 595,
            'height': 842
        }
    };



    const renderElements = (section) => {
        return (
            props.properties &&
            Object.keys(props.properties[section].elements).map((key) => {
                var data = props.properties[section].elements[key];
                var dStyle = Object.assign({}, data.style);
                var font = props.properties.total?.font || {fontFamily:'Arial', fontSize: 10, color:'#000000'}
                dStyle.color = props.properties.prop.fontColor;
                dStyle.color = props.properties.prop.fontColor;
                dStyle.fontSize = props.properties.prop.fontSize + "pt";
                dStyle.left=parseInt(dStyle.left);
                dStyle.zIndex=2

                return (
                    <div
                        key={data.id}
                        style={Object.assign(dStyle, font)}
                        id={data.id}
                        data-element={data.name}
                        data-section={section}
                        data-path="elements"
                        data-index={data.name.replace(".", "")}
                    >
                        {data.text}
                    </div>
                );
            })
        );
    };

    const renderVatSummary = () => {
        if( props.properties && props.properties.total && props.properties.total.VatSummary && Object.keys(props.properties.total.VatSummary).length !== 0) {
            var data = props.properties.total.VatSummary;
            var dStyle = Object.assign({}, data.style);
            dStyle.color = props.properties.prop.fontColor;
            dStyle.fontSize = props.properties.prop.fontSize + "pt";
            dStyle.zIndex=2
            return (

                <div
                    key={data.id}
                    style={dStyle}
                    id={data.id}
                    data-element={data.name}
                    data-section="VatSummary"
                    data-path="elements"
                    data-index={data.name.replace(".", "")}
                >
                    {data.text}
                </div>
            )
        }
    }

    const renderShapes = () => {
        let totalShapes=Object.keys(props.properties.total.shapes).filter(s => s!=='border').map((key) => {
            var prop = props.properties.total.shapes[key];
            // var dStyle = Object.assign({}, prop.style);
            var borderColor = props.properties.total.shapes.border.color
            var borderSize = props.properties.total.shapes.border.size
            var bgColor= props.properties.total.shapes.border.fillColor
            var dStyle = Object.assign({minWidth:'32px', minHeight:'16px'}, prop.style);
            dStyle.border = `${borderSize}px solid ${borderColor}`;
            dStyle.background=`${bgColor}`
            dStyle.left=parseInt(dStyle.left);
            dStyle.top=parseInt(dStyle.top)
            dStyle.width=parseInt(dStyle.width);
            dStyle.zIndex=1
            return (
                <div
                    key={key}
                    style={dStyle}
                    id={prop.id}
                    data-element={prop.name}
                    data-section={"total"}
                    data-path="shapes"
                    data-index={prop.data}
                >

                </div>
            );
        });
        console.log("totalShapes====>>", totalShapes)
        return totalShapes

      };



    return (

        <div style={{ width: '100%', }} >
            <div style={{
                height:  props.properties.prop.orientation==="landscape" ? props.heights.total * 0.01 * pageSettings[props.properties.prop.paperSize].width: props.heights.total * 0.01 * pageSettings[props.properties.prop.paperSize].height,
                position: 'absolute',
                backgroundColor: props.properties.total.background.showColor
                    ? props.properties.total.background.color
                    : "transparent",
            }}>
                <div
                >
                    {renderElements("total")}
                    {renderVatSummary()}
                    {renderShapes()}
                </div>
            </div>
        </div>


    )
}

