import React, { Component } from "react";
import { renderToString } from 'react-dom/server'
import { Row, Col, Button, Form, Modal, Toast, Card } from "react-bootstrap";
import { withStyles } from "@material-ui/core/styles";
import sha256 from "sha256";
// import SplitPane from "react-split-pane";
import { INVOICE_SECTIONS, RESIZE_DIRECTIONS } from "../../lib/config/enums";
import Ruler from "../../lib/plugins/ruler/Ruler";
import {
  createElement,
  createImageElement,
  companyPath,
  formatDate,
} from "../../lib/global/helpers";
import Split from 'react-split-it';
import Properties from "./new/properties";
import Head from "./new/head";
import Items from "./new/items";
import Total from "./new/total";
import Foot from "./new/foot";
import HTMLTemplate from "./HTMLTemplate";

import { GetSchema, SaveTemplate } from "../../lib/api/data/Request";

import { connect } from "react-redux";
import {
  updateProp,
  updateHead,
  updateItems,
  updateTotal,
  updateFoot,
  setSelectedElement,
  moveElement,
  resizeElement,
  setSectionHeight,
  removeHeaderImage,
  updateHeaderLogo,
  updateSplitSizes
} from "../../../redux/actions/TemplateActions";

import { library } from "@fortawesome/fontawesome-svg-core";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HTMLHeaderTemplate from "./HTMLHeaderTemplate";
import HTMLFooterTemplate from "./HTMLFooterTemplate";
import HTMLTotalTemplate from "./HTMLTotalTemplate";

const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

class NewTemplate extends Component {
  constructor(props) {
    super(props);

    //  console.log(this.props.properties);
    this.canvesWrapper = React.createRef();
    this.canves = React.createRef();

    this.headerContent = React.createRef();
    this.bodyContent = React.createRef();
    this.footerContent = React.createRef();

    // this.selectedElementResizeOn = false;
    // this.selectedElementMoveOn = false;

    this.state = {
      selectedElementName: "",
      wrapper: {
        height: 0,
        width: 0,
      },
      showPageGridLines: false,
      showGridLines: true,
      showPointer: false,
      selectedSection: INVOICE_SECTIONS.PROPS,
      attributes: null,

      measureSpec: {},
      showAlert: false,
      showToolTips: false,
      showSaveConfirm: false,
      isSaving: false,
      isSaved: false,
      successMessage: "",
      errorMessage: "",
      splitSizes: [0.25, 0.55, 0.1, 0.1],
      tempLogo: null,
      selectedElementResizeOn: false,
      selectedElementMoveOn: false
    };

    this._setSelectedSection = this._setSelectedSection.bind(this);
    this._slideMouseDown = this._slideMouseDown.bind(this);
    this._slideMouseMove = this._slideMouseMove.bind(this);
    this._slideMouseUp = this._slideMouseUp.bind(this);
    this._selectElementFromChild = this._selectElementFromChild.bind(this);
    this._renderCompanyElements = this._renderCompanyElements.bind(this);
    this._renderVatSummary = this._renderVatSummary.bind(this);
    this.editIcons = {
      bold: {
        title: "Bold",
        property: "fontWeight",
        value: "bold",
        icon: "type-bold",
      },

      italic: {
        title: "Italic",
        property: "fontStyle",
        value: "italic",
        icon: "type-italic",
      },

      strikethrough: {
        title: "Strikethrough",
        property: "textDecoration",
        value: "line-through",
        icon: "type-strikethrough",
      },

      underline: {
        title: "Underline",
        property: "textDecoration",
        value: "underline",
        icon: "type-underline",
      },

      h1: {
        title: "Header 1",
        property: "fontWeight",
        value: "bold",
        icon: "type-h1",
      },

      h2: {
        title: "Header 2",
        property: "fontWeight",
        value: "bold",
        icon: "type-h2",
      },

      h3: {
        title: "Header 3",
        property: "fontWeight",
        value: "bold",
        icon: "type-h3",
      },

      alignStart: {
        title: "Align Start",
        property: "fontWeight",
        value: "bold",
        icon: "align-start",
      },

      alignCenter: {
        title: "Align Center",
        property: "fontWeight",
        value: "bold",
        icon: "align-center",
      },

      alignEnd: {
        title: "Align End",
        property: "fontWeight",
        value: "bold",
        icon: "align-end",
      },

      fontSizePlus: {
        title: "Increase font size",
        property: "fontSize",
        value: +1,
        icon: "plus",
      },

      fontSizeMinus: {
        title: "Decrease font size",
        property: "fontSize",
        value: -1,
        icon: "dash",
      },
    };
  }

  componentDidMount() {
    GetSchema().then((response) => {
      if (response.isSuccess)
        this.setState(
          {
            attributes: response.data.Result,
          },
          function (e) {
            if (
              Object.keys(this.props.properties.head.companyProps).length === 0
            ) {
              var ele = [];
              for (const prop in this.state.attributes[companyPath()]
                .Properties) {
                var element = createElement(companyPath() + "." + prop);
                ele[prop] = {
                  show: false,
                  name: element.name,
                  text: element.text,
                  style: element.style,
                  data: element.data,
                  id: element.id,
                };
              }

              var properties = {
                ...this.props.properties.head,
                companyProps: ele,
              };
              this.props.updateHead(properties);
            }
          }
        );
      else {
        this.setState({ errorMessage: response.message });
      }
    });

    let totalheight = this.props.heights.head + this.props.heights.body + this.props.heights.total + this.props.heights.foot
    this.setState({
      isEdited: false,
      wrapper: {
        height: this.canvesWrapper.offsetHeight,
        width: this.canvesWrapper.offsetWidth,
      },

      sliderHeadMousedown: false,
      sliderBodyMouseDown: false,
      sliderTotalMouseDown: false,
      splitSizes: [this.props.heights.head / totalheight, this.props.heights.body / totalheight, this.props.heights.total / totalheight, this.props.heights.foot / totalheight]
    });
  }



  _setMeasureSpec = (value) => {
    this.setState({ measureSpec: value });
  };

  _changeProprtyProp = function (name, obj) {
    this.setState(
      {
        properties: {
          ...this.props.properties,
          [name]: obj,
        },
        isEdited: true,
      },
      function () {
        //console.log(name, obj, this.props.properties);
      }
    );
  };

  _selectElementFromChild = function (id) {
    var ele = document.getElementById(id);
    ele.click();
  };

  _setSelectedSection(obj) {
    this.setState({ selectedSection: obj });
  }

  _slideMouseDown = function (e, obj) {
    if (obj == "head")
      this.setState({
        sliderHeadMousedown: true,
        sliderBodyMouseDown: false,
        sliderTotalMouseDown: false,
      });
    else if (obj == "body")
      this.setState({
        sliderHeadMousedown: false,
        sliderBodyMouseDown: true,
        sliderTotalMouseDown: false,
      });
    else if (obj == "total")
      this.setState({
        sliderHeadMousedown: false,
        sliderBodyMouseDown: false,
        sliderTotalMouseDown: true,
      });
  };

  _slideMouseMove = function (e) {
    if (
      this.state.sliderHeadMousedown ||
      this.state.sliderBodyMouseDown ||
      this.state.sliderTotalMouseDown
    ) {
      let currentTargetRect = this.canves.getBoundingClientRect();
      var arrowY = e.clientY - currentTargetRect.top;

      var height = (arrowY / this.canves.offsetHeight) * 100;
      height = height < 0 ? 0 : height;

      var head_h = this.props.heights.head;
      var body_h = this.props.heights.body;
      var totl_h = this.props.heights.total;
      var foot_h = this.props.heights.foot;

      var head_new_h = head_h;
      var body_new_h = body_h;
      var totl_new_h = totl_h;
      var foot_new_h = foot_h;
      var diff = 0;

      //console.log(this.canves.offset);

      if (this.state.sliderHeadMousedown) {
        diff = height - head_h;
        head_new_h = height;
        body_new_h = body_h - diff;
      } else if (this.state.sliderBodyMouseDown) {
        height -= head_h;
        diff = height - body_h;
        body_new_h = height;
        totl_new_h = totl_h - diff;
      } else if (this.state.sliderTotalMouseDown) {
        height -= body_h + head_h;
        diff = height - totl_h;
        totl_new_h = height;
        foot_new_h = foot_h - diff;
      }

      this.props.setSectionHeight(
        head_new_h,
        body_new_h,
        totl_new_h,
        foot_new_h
      );
    }
  };

  _slideMouseUp = function (e) {
    this.setState({
      sliderHeadMousedown: false,
      sliderBodyMouseDown: false,
      sliderTotalMouseDown: false,
    });
  };

  _closeWindow = function (e) {
    if (this.state.isEdited) this.setState({ showAlert: true });
    else this.props.callbackFromParents(e);
  };

  _confirmClose = function (e) {
    this.props.callbackFromParents(e);
  };

  _onElementClick = (e) => {

    //  console.log(e)
    var name = e.target.getAttribute("id");
    if (name === null) {
      e.preventDefault();
      return false;
    }
    let section = e.target.getAttribute("data-section");
    let path = e.target.getAttribute("data-path");
    let index = e.target.getAttribute("data-index");

    if (name === null) return false;
    this.props.setSelectedElement(
      section,
      path,
      index,
      this.props.properties[section].elements[index]
    );

    this.setState({
      selectedElementName: name,
    });
  };

  _onShapeClick = (e) => {

    //  console.log(e)
    var name = e.target.getAttribute("id");
    if (name === null) {
      e.preventDefault();
      return false;
    }
    let section = e.target.getAttribute("data-section");
    let path = e.target.getAttribute("data-path");
    let index = e.target.getAttribute("data-index");

    if (name === null) return false;
    this.props.setSelectedElement(
      section,
      path,
      index,
      this.props.properties[section].shapes[index]
    );

    this.setState({
      selectedElementName: name,
    });
  };

  _onVatSummaryClick = (e) => {
    var name = e.target.getAttribute("id");
    if (name === null) {
      e.preventDefault();
      return false;
    }
    let section = 'total'//e.target.getAttribute("data-section");
    let path = "VatSummary"//e.target.getAttribute("data-path");
    let index = e.target.getAttribute("data-index");

    if (name === null) return false;
    this.props.setSelectedElement(
      section,
      path,
      index,
      this.props.properties[section].VatSummary
    );

    this.setState({
      selectedElementName: name,
    });
  }

  //#region Resize Element
  _resizeMouseDown = (e) => {
    console.log(e)
    e.preventDefault();
    // this.selectedElementResizeOn = true;
    this.setState({
      selectedElementResizeOn: true
    })
  };

  _resizeMouseMove = (e) => {
    if (this.state.selectedElementResizeOn) {
      var elementName = this.state.selectedElementName;
      var width = document.getElementById(elementName).offsetWidth;
      var height = document.getElementById(elementName).offsetHeight;
      var props = this.props.properties;
      var sect = props[this.section];
      //console.log(this.section);

      this.setState(
        {
          properties: {
            ...props,
            [this.section]: {
              ...sect,
              logo: {
                ...sect.logo,
                style: {
                  ...sect.logo.style,
                  width: width + e.movementX + "px",
                  height: height + e.movementY + "px",
                },
              },
              elements: [
                ...sect.elements.slice(0, this.index),
                {
                  ...sect.elements[this.index],
                  style: {
                    ...sect.elements[this.index].style,
                    width: width + e.movementX + "px",
                    height: height + e.movementY + "px",
                  },
                },
                ...sect.elements.slice(this.index + 1),
              ],
            },
          },
        },
        function () { }
      );
    }
  };

  _resizeMouseUp = (e) => {
    e.preventDefault();
    // this.selectedElementResizeOn = false;
    this.setState({
      selectedElementResizeOn: false
    })
  };

  _resizeMouseLeave = (e) => {
    e.preventDefault();
    // this.selectedElementResizeOn = false;
    this.setState({
      selectedElementResizeOn: false
    })
  };

  //#endregion

  //#region Move Element
  _moveMouseDown = (e) => {
    e.preventDefault();
    // this.selectedElementMoveOn = true;
    this.setState({
      selectedElementMoveOn: true
    })
  };

  _moveMouseMove = (e) => {
    if (this.state.selectedElementMoveOn) {
      var elementName = this.state.selectedElementName;
      var top = parseInt(document.getElementById(elementName).style.top) >= 0 ?
        parseInt(
          document
            .getElementById(elementName)
            .style.top.toString()
            .replace("px", "")
        ) : 0;
      var left = parseInt(document.getElementById(elementName).style.left) >= 0 ?
        parseInt(
          document
            .getElementById(elementName)
            .style.left.toString()
            .replace("px", "")
        ) : 0;
      this.props.moveElement(top + e.movementY, left + e.movementX);
    } else if (this.state.selectedElementResizeOn) {
      var elementName = this.state.selectedElementName;

      var width = document.getElementById(elementName).offsetWidth;
      var height = document.getElementById(elementName).offsetHeight;

      this.props.resizeElement(height + e.movementY, width + e.movementX);
    }
  };

  _moveMouseUp = (e) => {
    e.preventDefault();
    // this.selectedElementMoveOn = false;
    this.setState({
      selectedElementMoveOn: false
    })
  };

  _moveMouseLeave = (e) => {
    e.preventDefault();
    //this.selectedElementMoveOn = false;
    this.setState({
      selectedElementMoveOn: false
    })
  };
  //#endregion

  //#endregion

  _showToolTips = (obj) => {
    this.setState({ showToolTips: obj });
  };

  _renderElements = (section) => {
    var index = 0;
    return (
      this.props.properties &&
      Object.keys(this.props.properties[section].elements).map((key) => {
        var data = this.props.properties[section].elements[key];
        var font = this.props.properties[section]?.font || {fontFamily:'Arial', fontSize: 10}
        var dStyle = Object.assign({}, data.style);
        dStyle.color = this.props.properties.prop.fontColor;
        dStyle.fontSize = this.props.properties.prop.fontSize + "pt";

        index++;
        var id = sha256(section + "-ele-" + index);
        var isSelected = this.state.selectedElementName == data.id;
        return (
          <div
            onClick={(e) => this._onElementClick(e)}
            key={data.id}
            style={Object.assign(dStyle, font)}
            id={data.id}
            data-element={data.name}
            data-section={section}
            data-path="elements"
            data-index={data.name?data.name.replace(".", ""):""}
            className={
              "custom-element" + (isSelected ? " selected-element" : "")
            }
          >
            {data.text}
            {isSelected ? this._renderElementControl() : <></>}
          </div>
        );
      })
    );
  };

  _renderVatSummary = () => {
    var index = 0;

    if(this.props.properties.total.VatSummary && Object.keys(this.props.properties.total.VatSummary).length > 0) {
    var data = this.props.properties.total.VatSummary;
    var font = this.props.properties.total?.font || {fontFamily:'Arial', fontSize: 10}
    var dStyle = Object.assign({}, data.style);
    dStyle.width = 160
    dStyle.height = 60
    dStyle.color = this.props.properties.prop.fontColor;
    dStyle.fontSize = this.props.properties.prop.fontSize + "pt";

    index++;
    var id = sha256("vat-summary-ele-" + index);
    var isSelected = this.state.selectedElementName == data.id;
    return (
      <div
        onClick={(e) => this._onVatSummaryClick(e)}
        key={data.id}
        style={Object.assign(dStyle,font)}
        id={data.id}
        data-element={data.name}
        data-section="total"
        data-path="elements"
        data-index="VatSummary"
        className={
          "custom-element" + (isSelected ? " selected-element" : "")
        }
      >
        {data.text}
        {isSelected ? this._renderElementControl() : <></>}
      </div>
    );
      }

  };

  _renderShapes = (section) => {
    var index = 0;
    return (
      this.props.properties && this.props.properties[section].shapes && Object.keys(this.props.properties[section].shapes).length>0 &&
      Object.keys(this.props.properties[section].shapes).filter(s => s!=='border').map((key) => {
        var data = this.props.properties[section].shapes[key];
        var font = this.props.properties[section]?.font || {fontFamily:'Arial', fontSize: 10}
        var borderColor = this.props.properties[section].shapes.border.color
        var borderSize = this.props.properties[section].shapes.border.size
        var bgColor= this.props.properties[section].shapes.border.fillColor
        var dStyle = Object.assign({minWidth:'32px', minHeight:'16px'}, data.style);
        dStyle.border = `${borderSize}px solid ${borderColor}`;
        dStyle.background=`${bgColor}`

        index++;
        var id = sha256(section + "-ele-" + index);
        var isSelected = this.state.selectedElementName == data.id;
        return (
          <div
            onClick={(e) => this._onShapeClick(e)}
            key={data.id}
            style={Object.assign(dStyle, font)}
            id={data.id}
            data-element={data.name}
            data-section={section}
            data-path="shapes"
            data-index={data.name?data.name.replace(".", ""):""}
            className={
              "custom-element" + (isSelected ? " selected-element" : "")
            }
          >
            {isSelected ? this._renderElementControl() : <></>}
          </div>
        );
      })
    );
  };


  _renderImage = (section) => {
    if(this.props.properties.head && this.props.properties.head.logo && Object.keys(this.props.properties.head.logo).length>0) {
    var index = 0;
    index++;
    //console.log(this.props.properties.head.logo);
    var id = sha256(section + "img-ele" + index);
    var isSelected =
      this.state.selectedElementName == id;

    return this.props.properties.head.logo.showLogo &&
      this.props.properties.head.logo.logo !== null ? (
      <div
        onClick={(e) => {
          this._onElementClick(e)
        }}
        style={this.props.properties.head.logo.style}
        key={this.props.properties.head.logo.id}
        id={id}
        data-element={this.props.properties.head.logo.name}
        data-section={section}
        data-path="logo"
        data-index=""
        className={"custom-element" + (isSelected ? " selected-element" : "")}
      >
        <div>
          <img
            src={this.state.tempLogo ? this.state.tempLogo : this.props.properties.head.logo.logo}
            style={{
              width:
                (this.props.properties
                  ? this.props.properties.head.logo.logoSize
                  : 100) + "%",
              pointerEvents: "none",
            }}
          />
        </div>
        {isSelected ? this._renderElementControl() : <></>}
      </div>
    ) : (
      <></>
    );
    } else {
      return null
    }
  };

  _renderCompanyElements = () => {
    if(this.props.properties.head && this.props.properties.head.companyProps && Object.keys(this.props.properties.head.companyProps).length>0) {
    var index = 0;
    return Object.keys(this.props.properties?.head?.companyProps).map((key) => {
      var prop = this.props.properties.head.companyProps[key];
      var dStyle = Object.assign({}, prop.style);
      var font = this.props.properties.head?.font || {fontFamily:'Arial', fontSize: 10, color:"#000000ff"}
      dStyle.color = this.props.properties.prop.fontColor;
      dStyle.fontSize = this.props.properties.prop.fontSize + "pt";
      if (!prop.show) return false;
      index++;
      var id = sha256("head-com-" + index);
      var isSelected = this.state.selectedElementName == prop.id;
      return (
        <div
          onClick={(e) => this._onElementClick(e)}
          key={key}
          style={Object.assign(dStyle,font)}
          id={prop.id}
          data-element={prop.name}
          data-section={"head"}
          data-path="companyProps"
          data-index={prop.data}
          className={"custom-element" + (isSelected ? " selected-element" : "")}
        >
          {prop.text}
          {isSelected ? this._renderElementControl() : <></>}
        </div>
      );
    });
  } else {
    return null
  }
  };

  _renderDocumentTitle = () => {
    if(this.props.properties.head && this.props.properties.head.documentTitle && Object.keys(this.props.properties.head.documentTitle).length>0) {
    var id = sha256("head-title-ele-0");
    var title = this.props.properties.head.documentTitle;
    var isSelected = this.state.selectedElementName == title.id;

    var dStyle = Object.assign({}, title.style);
    // dStyle.color = this.props.properties.prop.fontColor;
    // dStyle.fontSize = "20pt";
    if (!title.show) return false;

    return this.props.properties &&
      this.props.properties.head.documentTitle.show ? (
      <div
        onClick={(e) => this._onElementClick(e)}
        style={dStyle}
        key="{key}"
        id={title.id}
        data-element={title.name}
        data-section={"head"}
        data-path="documentTitle"
        data-index={title.title}
        className={"custom-element-title custom-element" + (isSelected ? " selected-element" : "")}

      >
        {title.title}
        {isSelected ? this._renderElementControl() : <></>}
      </div>
    ) : (
      <></>
    );
    } else {
      return null
    }
  };

  _renderElementControl = () => {
    return (
      <div className="resize">
        <span
          className="top-left"
          onMouseDown={(e) => this._moveMouseDown(e)}
          onMouseUp={(e) => this._moveMouseUp(e)}
        ></span>

        <span
          className="bottom-right"
          onMouseDown={(e) => this._resizeMouseDown(e)}
          onMouseUp={(e) => this._resizeMouseUp(e)}
        ></span>
      </div>
    );
  };

  _renderBodyElements = (displayData) => {
    // const totalWidth = Object.keys(displayData).map(t => displayData[t])
    // .reduce((a, b) => {
    //   return  a + Number(b.width?b.width:10); // returns object with property x
    // }, 0)
    // console.log("totalWidth===>", totalWidth)
    return (
      <table
        style={{
          width: "100%",
        }}
      >
        <thead>
          <tr style={{ height: "32px" }}>


            {
              displayData.map((obj, key) => {
                // var obj = displayData[key];

                if (displayData[key]) {
                  var dStyle = Object.assign({}, obj.style);
                  dStyle.padding = "5px 0px 5px 5px";
                  // dStyle.textAlign = "center";
                  dStyle.backgroundColor =
                    this.props.properties.items.layout.tableHeader.backgroundColor;
                  dStyle.fontSize =
                    this.props.properties.items.layout.tableHeader.fontSize + "pt";
                  dStyle.fontFamily =  this.props.properties.items.layout.tableHeader.fontFamily;
                  dStyle.color =
                    this.props.properties.items.layout.tableHeader.fontColor;
                  return (
                    <td
                      width={obj.width ? (obj.width / (displayData.reduce((a, v) => { return (a + Number(v.width ? v.width : 10)) }, 0)) * 100 + "%") : 'auto'}
                      style={{
                        padding: "5px 0px 5px 5px",
                        // textAlign: "center",
                        backgroundColor:
                          this.props.properties.items.layout.tableHeader
                            .backgroundColor,
                        fontSize:
                          this.props.properties.items.layout.tableHeader.fontSize +
                          "pt",
                        fontFamily: this.props.properties.items.layout.tableHeader.fontFamily,
                        color:
                          this.props.properties.items.layout.tableHeader.fontColor,
                      }}
                      key={`dd_${key}`}
                    >
                      {obj.stringFormat ? obj.stringFormat : obj.header}
                    </td>
                  );
                }


              })
            }

          </tr>
        </thead>
        <tbody>
          {[...Array(50)].map((x, i) => (
            <tr key={`tr1k_${i}`}>
              {Object.keys(this.props.properties.items.items).map((key, j) => {

                if (this.props.properties.items.items[key]) {

                  return (
                    <td
                      style={{
                        padding: "10px 0px 10px 20px",
                        textAlign: "left",
                        borderBottom:
                          "1px solid " +
                          this.props.properties.items.layout.tableBorder,
                        backgroundColor:
                          this.props.properties.items.layout.itemRow
                            .backgroundColor,
                        fontSize:
                          this.props.properties.items.layout.itemRow.fontSize +
                          "pt",
                        color:
                          this.props.properties.items.layout.itemRow.fontColor,
                      }}
                      key={`td1k_${j}`}
                    >
                      {"---"}
                    </td>
                  );
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  _handleSplitSize = (s) => {
    console.log("XXXXX==>>", s)
    this.props.updateSplitSizes(s.map(size => Number(size * 100)))
    this.setState({
      splitSizes: s
    })

  }

  _saveTemplate = async(e) => {

    const htmlString = renderToString(<HTMLTemplate properties={this.props.properties} heights={this.props.heights} tempLogo={this.state.tempLogo} />);
    const htmlHeaderString = renderToString(<HTMLHeaderTemplate properties={this.props.properties} heights={this.props.heights} tempLogo={this.state.tempLogo} />);
    const htmlTotalString = renderToString(<HTMLTotalTemplate  properties={this.props.properties} heights={this.props.heights} tempLogo={this.state.tempLogo} />);
    const htmlFooterString = renderToString(<HTMLFooterTemplate properties={this.props.properties} heights={this.props.heights} tempLogo={this.state.tempLogo} />);
    let encodedHTMLString = encodeURI(htmlString)
    let encodedHTMLHeaderString = encodeURI(htmlHeaderString)
    let encodedHTMLFooterString = encodeURI(htmlFooterString)
    let encodedHTMLTotalString = encodeURI(htmlTotalString)
    console.log(htmlString);
    if (this.state.isSaving) return false;
    this.setState({ isSaving: true }, function () {
      SaveTemplate(encodedHTMLString, encodedHTMLHeaderString, encodedHTMLFooterString, encodedHTMLTotalString).then((response) => {
        this.setState({ isSaved: response.data.Status });
        if (response.data.Status) {

          this.setState(
            {
              isSaving: false,
              isEdited: false,
              successMessage:
                "The template is saved. Please keep editing the template or Click the close(x) button in the top right corner of the screen",
            },
            function () {
              const timer = setTimeout(() => {
                // this.props.callbackFromParents(e);
                //console.log("Redirecting...");
              }, 3000);
            }
          );
        } else {
          this.setState({
            errorMessage: response.data
              ? response.data.Result
              : response.message,
            isSaving: false,
          });
        }
        //console.log(response.data.Status);
      });
    });
  };

  _handleImageRemove = () => {
    this.props.removeHeaderImage()
    this.setState({
      tempLogo: null
    })
  }

  renderMenu() {
    const arr = this.state.selectedSection.toString().toLowerCase();
    var elements = Object.keys(INVOICE_SECTIONS).map((e, k) => {
      e = e.toString().toLowerCase();
      return (
        <li
          key={`li1key_${k}`}
          className="nav-item"
          onClick={() => this._setSelectedSection(e)}
        >
          <a className={"nav-link" + (arr === e ? " active" : "")}>
            {(() => {
              switch (e) {
                case INVOICE_SECTIONS.PROPS:
                  return <FontAwesomeIcon icon="border-all" />;

                case INVOICE_SECTIONS.HEAD:
                  return <FontAwesomeIcon icon="angle-up" />;

                case INVOICE_SECTIONS.ITEMS:
                  return <FontAwesomeIcon icon="align-justify" />;

                case INVOICE_SECTIONS.TOTAL:
                  return <FontAwesomeIcon icon="file-alt" />;

                case INVOICE_SECTIONS.FOOT:
                  return <FontAwesomeIcon icon="angle-down" />;
              }
            })()}
            <span>{e}</span>
          </a>
        </li>
      );
    });

    return elements;
  }

  render() {
    return (
      <>
        <div
          className="fullscreen-bg"
          onMouseUp={(e) => {
            // this.selectedElementResizeOn = false;
            // this.selectedElementMoveOn = false;
            this.setState({
              selectedElementResizeOn: false,
              selectedElementMoveOn: false
            })
          }}
          onMouseMove={(e) => this._moveMouseMove(e)}
        >
          <div className="column nav-column bg-primary">
            <ul className="nav navbar-nav flex-column">{this.renderMenu()}</ul>
          </div>
          <div className="column nav-options-column">
            <div
              className={
                this.state.selectedSection == INVOICE_SECTIONS.PROPS
                  ? ""
                  : "d-none"
              }
            >
              <Properties
                {...this.props}
                callbackFromParents={(obj) => {
                  this._changeProprtyProp("prop", obj);
                }}
                showToolTips={(obj) => {
                  this._showToolTips(obj);
                }}
              />
            </div>
            <div
              className={
                this.state.selectedSection == INVOICE_SECTIONS.HEAD
                  ? ""
                  : "d-none"
              }
            >
              {this.state.attributes && (
                <Head
                  {...this.props}
                  attributes={this.state.attributes}
                  properties={this.props.properties.head}
                  callbackFromParents={(obj) => {
                    this._changeProprtyProp("head", obj);
                  }}
                  showToolTips={(obj) => {
                    this._showToolTips(obj);
                  }}
                  onElementSelected={this._selectElementFromChild}
                  handleImageRemove={this._handleImageRemove}
                  updateTempLogo={(tempimg) => this.setState({ tempLogo: tempimg })}
                />
              )}
            </div>
            <div
              className={
                this.state.selectedSection == INVOICE_SECTIONS.ITEMS
                  ? ""
                  : "d-none"
              }
            >
              {this.state.attributes && (
                <Items
                  {...this.props}
                  attributes={this.state.attributes}
                  callbackFromParents={(obj) => {
                    this._changeProprtyProp("items", obj);
                  }}
                  showToolTips={(obj) => {
                    this._showToolTips(obj);
                  }}
                />
              )}
            </div>
            <div
              className={
                this.state.selectedSection == INVOICE_SECTIONS.TOTAL
                  ? ""
                  : "d-none"
              }
            >
              {this.state.attributes && (
                <Total
                  {...this.props}
                  onElementSelected={this._selectElementFromChild}
                  attributes={this.state.attributes}
                  callbackFromParents={(obj) => {
                    this._changeProprtyProp("total", obj);
                  }}
                />
              )}
            </div>
            <div
              className={
                this.state.selectedSection == INVOICE_SECTIONS.FOOT
                  ? ""
                  : "d-none"
              }
            >
              {this.state.attributes && (
                <Foot
                  {...this.props}
                  attributes={this.state.attributes}
                  callbackFromParents={(obj) => {
                    this._changeProprtyProp("foot", obj);
                  }}
                  showToolTips={(obj) => {
                    this._showToolTips(obj);
                  }}
                  onElementSelected={this._selectElementFromChild}
                />
              )}
            </div>
          </div>
          <div className="column nav-editor border-0">
            <div className="new-template">
              <div className="browser">
                <div className="browser_wrapper">
                  <div className="address-bar border-bottom d-flex">
                    <div className="address-bar_dots mr-auto">
                      <span className="dot-1"></span>
                      <span className="dot-2"></span>
                      <span className="dot-3"></span>
                    </div>
                    <div className="ms-auto d-flex align-items-center">
                      <Form className="me-auto">
                        <ul className="address-bar_menu">
                          <li key="chkshowPageGridLines">
                            <Form.Group controlId="chkshowPageGridLines">
                              <Form.Check
                                onChange={(e) => {
                                  this.setState({
                                    showPageGridLines: e.target.checked,
                                  });
                                }}
                                checked={this.state.showPageGridLines}
                                label="Show page grid-lines"
                              />
                            </Form.Group>
                          </li>
                          <li key="chkshowGridLines">
                            <Form.Group controlId="">
                              <Form.Check
                                onChange={(e) => {
                                  this.setState({
                                    showGridLines: e.target.checked,
                                  });
                                }}
                                checked={this.state.showGridLines}
                                name={"showGridLines"}
                                label="Show grid-lines"
                              />
                            </Form.Group>
                          </li>
                          <li key="chkshowPointer">
                            <Form.Group controlId="chkshowPointer">
                              <Form.Check
                                onChange={(e) => {
                                  this.setState({
                                    showPointer: e.target.checked,
                                  });
                                }}
                                checked={this.state.showPointer}
                                label="Show pointer"
                              />
                            </Form.Group>
                          </li>
                        </ul>
                      </Form>
                      <a
                        className="ms-auto p-3"
                        onClick={(e) => this._closeWindow(e)}
                        style={{ cursor: "pointer" }}
                      >
                        <FontAwesomeIcon icon="times" />
                      </a>
                    </div>
                  </div>
                  <div
                    className="canves-wrapper"
                    onMouseMove={(e) => this._slideMouseMove(e)}
                    ref={(ref) => (this.canvesWrapper = ref)}
                  >
                    <div
                      className="canves"
                      style={this.props.properties.prop.orientation === "portrait" ? { width: '210mm', height: '297mm' } : { width: '297mm', height: '210mm' }}
                      ref={(ref) => (this.canves = ref)}
                    ></div>
                    <Ruler
                      width={this.state.wrapper.width}
                      height={this.state.wrapper.height}
                      showPageGridLines={this.state.showPageGridLines}
                      showGridLines={this.state.showGridLines}
                      showPointer={this.state.showPointer}
                      setMeasureSpec={(e) => this._setMeasureSpec(e)}
                      orientation={this.props.properties.prop.orientation}
                      paperSize={this.props.properties.prop.paperSize}
                    >
                      <div className="canves-content-wrapper">
                        <div
                          className="canves-content"
                          style={{
                            backgroundColor: this.props.properties.prop
                              .background.showColor
                              ? this.props.properties.prop.background.color
                              : "transparent",
                            width: this.props.properties.prop.orientation === "portrait" ? '210mm' : '297mm',
                            height: this.props.properties.prop.orientation === "portrait" ? '297mm' : '210mm',
                          }}
                        >
                          <div id="templateContainer">
                            <div className="simple-vertical">
                              <Split
                                className="split-vertical"
                                style={this.props.properties.prop.orientation === "portrait" ? { height: '297mm' } : { height: '210mm' }}
                                direction="vertical"
                                sizes={this.state.splitSizes}
                                onSetSizes={this._handleSplitSize}
                              >
                                <div
                                  className="content canves-header "
                                  style={{
                                    // height: this.props.heights.head + "%",
                                    height: '100%',
                                    backgroundColor: this.props.properties.head
                                      .background.showColor
                                      ? this.props.properties.head.background.color
                                      : "transparent",
                                  }}
                                >
                                  <div
                                    className="canves-content-children"
                                    style={{
                                      marginTop:
                                        this.props.properties.prop.margin ? this.props.properties.prop.margin.top *
                                          this.state.measureSpec.in : this.state.measureSpec.in +
                                        "px",
                                      marginLeft:
                                        this.props.properties.prop.margin ?
                                          this.props.properties.prop.margin.left *
                                          this.state.measureSpec.in : this.state.measureSpec.in +
                                          "px",
                                      marginRight:
                                        this.props.properties.prop.margin ? this.props.properties.prop.margin.right *
                                          this.state.measureSpec.in : this.state.measureSpec.in +
                                        "px",
                                      borderBottom: 0,
                                    }}
                                  >
                                    <div>
                                      {this._renderCompanyElements()}
                                      {this._renderElements("head")}
                                      {this._renderImage("head")}
                                      {this._renderDocumentTitle()}
                                      {this._renderShapes("head")}
                                    </div>
                                  </div>
                                  <span className="info">Header</span>
                                  <span
                                    onMouseDown={(e) =>
                                      this._slideMouseDown(e, "head")
                                    }
                                    onMouseUp={(e) => this._slideMouseUp(e)}
                                    className="slider"
                                  ></span>
                                </div>


                                {/** end head **/}

                                <div
                                  className="content canves-body"
                                  style={{
                                    height: this.props.heights.body + "%",
                                  }}
                                >
                                  <div
                                    className="canves-content-children"
                                    style={{
                                      marginLeft:
                                        this.props.properties.prop.margin ? this.props.properties.prop.margin.left *
                                          this.state.measureSpec.in : this.state.measureSpec.in +
                                        "px",
                                      marginRight:
                                        this.props.properties.prop.margin ? this.props.properties.prop.margin.right *
                                          this.state.measureSpec.in : this.state.measureSpec.in +
                                        "px",
                                      overflow: "hidden",
                                      borderTop: 0,
                                      borderBottom: 0,
                                    }}
                                  >
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                      }}
                                    >
                                      {this._renderBodyElements(Object.keys(this.props.properties.items.items).map(t => this.props.properties.items.items[t]).filter(d => d && d.width !== undefined))}
                                    </div>
                                  </div>
                                  <span className="info">Body</span>
                                  <span
                                    onMouseDown={(e) =>
                                      this._slideMouseDown(e, "body")
                                    }
                                    onMouseUp={(e) => this._slideMouseUp(e)}
                                    className="slider"
                                  ></span>
                                </div>
                                {/** end body **/}

                                <div
                                  className="content canves-total"
                                  style={{
                                    // height: this.props.heights.total + "%",
                                    height: '100%',
                                    backgroundColor: this.props.properties.total
                                      .background.showColor
                                      ? this.props.properties.total.background.color
                                      : "transparent",
                                  }}
                                >
                                  <div
                                    className="canves-content-children"
                                  >
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        bottom: 0,
                                      }}
                                    >
                                      {this._renderElements("total")}
                                      {this._renderVatSummary()}
                                      {this._renderShapes("total")}
                                    </div>
                                  </div>
                                  <span className="info">Totals</span>
                                  <span
                                    onMouseDown={(e) =>
                                      this._slideMouseDown(e, "total")
                                    }
                                    onMouseUp={(e) => this._slideMouseUp(e)}
                                    className="slider"
                                  ></span>
                                </div>
                                {/** end total **/}



                                <div
                                  className="content  canves-footer"
                                  style={{
                                    // height: this.props.heights.foot + "%",
                                    height: "100%",
                                    backgroundColor: this.props.properties.foot
                                      .background.showColor
                                      ? this.props.properties.foot.background.color
                                      : "transparent",
                                  }}
                                >
                                  <div
                                    className="canves-content-children"

                                  >
                                    <div
                                      style={{
                                        position: "absolute",
                                      }}
                                    >
                                      {this._renderElements("foot")}
                                      {this._renderShapes("foot")}
                                    </div>
                                  </div>
                                  <span className="info">Footer</span>
                                </div>



                              </Split>
                            </div>
                            {/** end foot **/}
                          </div>



                        </div>
                      </div>
                    </Ruler>
                  </div>
                </div>
              </div>
              {this.state.selectedElementName !== "" && (
                <div className="editor-wrapper">
                  <div className="editor-panel bg-primary">
                    <div
                      style={{
                        maxHeight: "50vh",
                        maxWidth: "100%",
                        display: "flex",
                      }}
                    >
                      <div style={{ overflow: "auto", flex: "1 1 0%" }}>
                        <div>
                          {Object.keys(this.editIcons).map((i) => {
                            var obj = this.editIcons[i];
                            //console.log(i, obj);
                            return (
                              <button
                                className="panel-react-outer panel-react-inner"
                                tabIndex="0"
                                type="button"
                                title={obj.title}
                                onClick={(e) => {
                                  var ele = document.getElementById(
                                    this.state.selectedElementName
                                  );
                                  let section =
                                    ele.getAttribute("data-section");
                                  let path = ele.getAttribute("data-path");
                                  let index = ele.getAttribute("data-index");
                                  ele.style[obj.property] = obj.value;
                                  //  console.log(ele.style);
                                }}
                              >
                                <i
                                  style={{ fontSize: "1.3rem" }}
                                  className={"text-white bi bi-" + obj.icon}
                                ></i>
                              </button>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              )}
            </div>
          </div>

          {this.state.showToolTips && (
            <div
              style={{
                position: "absolute",
                top: "50px",
                bottom: 0,
                right: 0,
                transition: "right .2s ease-in-out",
                width: "450px",
                zIndex: 1000001,
                backgroundColor: "#fff",
                boxShadow: "-2px 5px 10px 1px rgb(214, 214, 214)",
              }}
            >
              <div
                className="flyout-header pagetip-header "
                style={{
                  borderBottom: "1px solid #eee",
                  top: 0,
                  left: 0,
                  right: 0,
                  position: "absolute",
                }}
              >
                <div
                  className="section notification-header"
                  style={{
                    padding: "14px 20px",
                    borderBottom: 0,
                    display: "flex",
                  }}
                >
                  <h5 className="me-auto mb-0">
                    <span
                      className="font-large align-middle"
                      style={{ margin: "12px 0px", display: "block" }}
                    >
                      Page Tips
                    </span>
                  </h5>
                  <a
                    style={{ marginTop: "10px", marginRight: "8px" }}
                    className="ms-auto text-muted"
                    onClick={(e) => {
                      this.setState({ showToolTips: false });
                    }}
                  >
                    <FontAwesomeIcon icon="times" />
                  </a>
                </div>
              </div>
              <div
                className="flyout-body sidebar-body "
                style={{
                  top: "66px",
                  bottom: 0,
                  overflowY: "scroll",
                  backgroundColor: "#FFFFFFFF",
                  paddingBottom: "50px",
                  left: 0,
                  right: 0,
                  position: "absolute",
                }}
              >
                <div id="ember2262" className="ember-view">
                  <div id="ember2263" className="ember-view"></div>
                  <div className="sub-section">
                    <div id="ember2264" className="faq-search ember-view">
                      <div className="search-field">
                        <div className="input-group btn-group ">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              style={{ height: "32px" }}
                            >
                              <FontAwesomeIcon icon="search" />
                            </span>
                          </div>
                          <input
                            placeholder="Search Help Resources"
                            id="ember2265"
                            className="form-control ember-text-field ember-view"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <ul className="faq-list font-small ml-n2">
                      <div id="ember2266" className="ember-view">
                        <div className="loading text-center ">
                          <div className="load-circle1"></div>
                          <div className="load-circle2"></div>
                          <div className="load-circle3"></div>
                          <div className="load-circle4"></div>
                          <div className="load-circle5"></div>
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className="fill list-header topgrad bg-light border-top"
            style={{
              left: "60px",
              bottom: "0px",
              position: "absolute",
              zIndex: 100000002,
              width: "340px",
              padding: "12px",
            }}
          >
            <div className="list-filter d-flex">
              <button
                className="btn btn-primary ember-view me-auto"
                type="submit"
                style={{ width: "100px" }}
                onClick={(e) => this._saveTemplate(e)}
              >
                {this.state.isSaving ? (
                  <FontAwesomeIcon
                    icon="spinner"
                    color="white"
                    className="rotate me-2"
                  />
                ) : (
                  <>Save</>
                )}
              </button>
              <button
                className="btn btn-link ms-auto"
                style={{ width: "60px" }}
                onClick={(e) => this._closeWindow(e)}
              >
                Close
              </button>
            </div>
          </div>
        </div>

        <Modal
          show={this.state.showAlert}
          onHide={(e) => {
            this.setState({ showAlert: false });
          }}
          style={{ zIndex: 10600, backgroundColor: "#484848b3" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Alert</Modal.Title>
          </Modal.Header>
          <Modal.Body>The data is modified and not saved</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={(e) => {
                this.setState({ showAlert: false }, function () {
                  this._confirmClose(e);
                });
              }}
            >
              Close Window
            </Button>
            <Button
              variant="primary"
              onClick={(e) => {
                this._saveTemplate();
              }}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <Toast
          show={
            this.state.successMessage !== "" || this.state.errorMessage !== ""
          }
          onClose={() => {
            this.setState({
              successMessage: "",
              errorMessage: "",
            });
          }}
        >
          <Toast.Header
            className={
              (this.state.errorMessage === "" ? "bg-success" : "bg-danger") +
              " text-white"
            }
          >
            <strong className="me-auto">
              {this.state.errorMessage === "" ? "Hooray!" : "Oooops!!!"}
            </strong>
            <small className="pe-2">
              {formatDate(new Date(), "dddd h:mmtt d MMM yyyy")}
            </small>
          </Toast.Header>
          <Toast.Body>
            {this.state.errorMessage === "" ? (
              <>{this.state.successMessage}</>
            ) : (
              <>{this.state.errorMessage}</>
            )}
          </Toast.Body>
        </Toast>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    properties: {
      prop: state.template.prop,
      head: state.template.head,
      items: state.template.items,
      total: state.template.total,
      foot: state.template.foot
    },
    selectedElement: state.template.selectedElement,
    heights: state.template.heights,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProp: (e) => dispatch(updateProp(e)),
    updateHead: (e) => dispatch(updateHead(e)),
    updateItems: (e) => dispatch(updateItems(e)),
    updateTotal: (e) => dispatch(updateTotal(e)),
    updateFoot: (e) => dispatch(updateFoot(e)),
    setSelectedElement: (section, path, index, element) =>
      dispatch(setSelectedElement(section, path, index, element)),
    moveElement: (top, left) => dispatch(moveElement(top, left)),
    resizeElement: (height, width) => dispatch(resizeElement(height, width)),
    setSectionHeight: (head, body, total, foot) =>
      dispatch(setSectionHeight(head, body, total, foot)),
    removeHeaderImage: () => dispatch(removeHeaderImage()),
    updateHeaderLogo: (logo) => dispatch(updateHeaderLogo(logo)),
    updateSplitSizes: (data) => dispatch(updateSplitSizes(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewTemplate);

function SaveConfirmModel(props) {
  //console.log(props);

  return props.show ? (
    <Modal
      {...props}
      style={{ zIndex: 10600, backgroundColor: "#484848b3" }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Centered Modal</h4>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  ) : (
    <></>
  );
}
