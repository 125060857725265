// import { axiosCallNormal } from "../apiCall";
// import { SERVER_METHODS } from "../../config/enums";
// import PATHS from "../../../../json/paths.json";
import axios from 'axios';
import jwt from 'jsonwebtoken';
import sha256 from "sha256";

const { REACT_APP_ImageUploadAud, REACT_APP_ImageUploadIss, REACT_APP_ImageUploadjwtPrivate, REACT_APP_ImageUpload_BASEURL } = process.env;

export function uploadImage(image) {
  const formData = new FormData();
  // formData.append("image", image);

  //  const { blobFile } = image;

  const newImage = new File([image], image.name, { type: image.type });
  let fd = new FormData();
  fd.append(newImage.name, newImage);
  //console.log(newImage);

  const timestamp = Math.floor(Date.now() / 1000) + (60 * 15);

  const environmentFolderName =process.env.NODE_ENV === 'production'
					? 'production'
					: 'dev';

  const filePath = `/${environmentFolderName}/${sessionStorage.getItem('orgID')}/templates/`; // Side Note: In here "erp" is synonym for "Web Neurolage"

  var payload = {
    aud: REACT_APP_ImageUploadAud,
    exp: timestamp,
    iss: REACT_APP_ImageUploadIss,
    usertoken: sessionStorage.getItem('accessToken')
  };

  var signOptions = {
    algorithm: "RS256"
  };

  var privateKey = REACT_APP_ImageUploadjwtPrivate.replace(/\\n/g, '\n');


  var token = jwt.sign(payload, privateKey, signOptions);


  //const formData = new FormData();
  formData.append('file', newImage);
  formData.append('fileName', sha256(image.name + Date.now()));
  formData.append('filePath', filePath);


  const axconfig = {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    }
  };

  return axios.post(REACT_APP_ImageUpload_BASEURL, formData, axconfig)
  // return axios.post('https://api.retailpacx.com/homelayout/upload/' + image.name, formData, axconfig)


  //var path = PATHS.UploadImage;
  //return axiosCallNormal(SERVER_METHODS.POST, path, {}, formData, false);
}
