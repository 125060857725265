import { axiosCallNormal } from "../apiCall";
import { SERVER_METHODS } from "../../config/enums";
import { stringFormat, formatDate } from "../../global/helpers";
import PATHS from "../../../../json/paths.json";
import store from "../../../../redux/store";

const { REACT_APP_webneurolageAPI } = process.env;


export function SearchOrder(posType, templateType, id) {
  const state = store.getState();

  var data = {
    orgID: sessionStorage.getItem('orgID'),
    cloudLocationID: sessionStorage.getItem('LocationID'),
    CloudSalesOrderID: id,
  };

  //v1/{pos}/{template}/fetch
  var path =
    REACT_APP_webneurolageAPI +
    stringFormat(PATHS.GetSchmaWithData, {
      pos: posType,
      dataTemplate: templateType,
      orgid: id,
    });
  return axiosCallNormal(SERVER_METHODS.POST, path, {}, data, false);
}
