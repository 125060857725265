import { CurrentActionTypes } from "../constants/actionTypes";

const initialState = {
  title: "",
  subtitle: "",
  selectedPOS: "epos",
  selectedTemplateType: "",
  selectedTemplate: null,
};

export const currentReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case CurrentActionTypes.SET_TITLE:
      return {
        ...state,
        title: data.title,
        subtitle: data.subtitle,
      };

    case CurrentActionTypes.SET_SELECTED_POS:
      return {
        ...state,
        selectedPOS: data,
      };

    case CurrentActionTypes.SET_SELECTED_TEMPLATE_TYPE:
      return {
        ...state,
        selectedTemplateType: data,
      };

    case CurrentActionTypes.SET_SELECTED_TEMPLATE:
      return {
        ...state,
        selectedTemplate: data,
      };

    default:
      return state;
  }
};
