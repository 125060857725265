export const GlobalActionTypes = {
  SET_CONFIGURATION: "SET_CONFIGURATION",
  SET_CURRENT_USER: "SET_CURRENT_USER",
  SET_CLOUD_USER: "SET_CLOUD_USER",
};

export const CurrentActionTypes = {
  SET_TITLE: "SET_TITLE",
  SET_SELECTED_POS: "SET_SELECTED_POS",
  SET_SELECTED_TEMPLATE_TYPE: "SET_SELECTED_TEMPLATE_TYPE",
  SET_SELECTED_TEMPLATE: "SET_SELECTED_TEMPLATE",
  DELETE_TEMPLATE: "DELETE_TEMPLATE"
};

export const TemplateActionTypes = {
  UPDATE_PROPS: "UPDATE_PROPS",
  UPDATE_HEAD: "UPDATE_HEAD",
  UPDATE_ITEMS: "UPDATE_ITEMS",
  UPDATE_TOTAL: "UPDATE_TOTAL",
  UPDATE_FOOT: "UPDATE_FOOT",
  SET_SELECTED_ELEMENT: "SET_SELECTED_ELEMENT",
  MOVE_ELEMENT: "MOVE_ELEMENT",
  RESIZE_ELEMENT: "RESIZE_ELEMENT",
  SET_SECTION_HEIGHT: "SET_SECTION_HEIGHT",
  UPDATE_LOGO: "UPDATE_LOGO",
  SET_DATA: "SET_DATA",
  RESET_DATA: "RESET_DATA",
  REMOVE_HEADER_IMAGE: "REMOVE_HEADER_IMAGE",
  UPDATE_SPLIT_SIZES: "UPDATE_SPLIT_SIZES"
};
