class AxiosMessage {
  constructor(orgID, userID, cloudUserID, data) {
    this.createdTime = new Date();
    this.orgID = orgID;
    this.cloudUser = cloudUserID;
    this.data = data;
    this.user = userID;
    this.isSuccess = false;
    this.axiosResponse = null;
    this.data = null;
    this.errorRequest = null;
    this.message = "";
  }
}

export default AxiosMessage;
