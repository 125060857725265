import React from 'react'

export default function HTMLHeaderTemplate(props) {
    const pageSettings = {
        'a4': {
            'width': 794,
            'height': 1123
        },
        'letter': {
            'width': 595,
            'height': 842
        }
    };

    const renderImage = (section) => {

        return props.properties.head.logo.showLogo &&
            props.properties.head.logo.logo !== null ? (
            <div
                style={props.properties.head.logo.style}
                key={props.properties.head.logo.id}
                data-element={props.properties.head.logo.name}
                data-section={section}
                data-path="logo"
                data-index=""
            >
                <div>
                    <img
                        src={props.tempLogo ? props.tempLogo : props.properties.head.logo.logo}
                        style={{
                            width:
                                (props.properties
                                    ? props.properties.head.logo.logoSize
                                    : 100) + "%",
                            pointerEvents: "none",
                        }}
                    />
                </div>
            </div>
        ) : (
            <></>
        );
    };

    const renderElements = (section) => {
        return (
            props.properties &&
            Object.keys(props.properties[section].elements).map((key) => {
                var data = props.properties[section].elements[key];
                var dStyle = Object.assign({}, data.style);
                var font = props.properties[section]?.font || {fontFamily:'Arial', fontSize: 10}
                dStyle.color = props.properties.prop.fontColor;
                dStyle.fontSize = props.properties.prop.fontSize + "pt";
                dStyle.left=parseInt(dStyle.left);
                dStyle.top=parseInt(dStyle.top);

                return (
                    <div
                        key={data.id}
                        style={Object.assign(dStyle, font)}
                        id={data.id}
                        data-element={data.name}
                        data-section={section}
                        data-path="elements"
                        data-index={data.name.replace(".", "")}
                    >
                        {data.text}
                    </div>
                );
            })
        );
    };

    const renderCompanyElements = () => {
        return Object.keys(props.properties.head.companyProps).map((key) => {
            var prop = props.properties.head.companyProps[key];
            var dStyle = Object.assign({}, prop.style);
            var font = props.properties.head?.font || {fontFamily:'Arial', fontSize: 10, color:'#000000'}
            dStyle.color = props.properties.prop.fontColor;
            dStyle.fontSize = props.properties.prop.fontSize + "pt";
            dStyle.left=parseInt(dStyle.left);
            dStyle.top=parseInt(dStyle.top)
            if (!prop.show) return false;
            return (
                <div
                    key={key}
                    style={Object.assign(dStyle, font)}
                    id={prop.id}
                    data-element={prop.name}
                    data-section={"head"}
                    data-path="companyProps"
                    data-index={prop.data}
                >
                    {prop.text}
                </div>
            );
        });
    };

    const renderDocumentTitle = () => {
        var title = props.properties.head.documentTitle;

        var dStyle = Object.assign({}, title.style);
        dStyle.color = props.properties.prop.fontColor;
        dStyle.fontSize = "20pt";
        dStyle.left=parseInt(dStyle.left);
        dStyle.top=parseInt(dStyle.top)
        dStyle.width=dStyle.width
        if (!title.show) return false;

        return props.properties &&
            props.properties.head.documentTitle.show ? (
            <div
                style={dStyle}
                key="{key}"
                id={title.id}
                data-element={title.name}
                data-section={"head"}
                data-path="documentTitle"
                data-index={title.title}
            >
                {title.title}
            </div>
        ) : (
            <></>
        );
    };

    const renderShapes = () => {
        return Object.keys(props.properties.head.shapes).filter(s => s!=='border').map((key) => {
            var prop = props.properties.head.shapes[key];
            // var dStyle = Object.assign({}, prop.style);
            var borderColor = props.properties.head.shapes.border.color
            var borderSize = props.properties.head.shapes.border.size
            var bgColor= props.properties.head.shapes.border.fillColor
            var dStyle = Object.assign({minWidth:'32px', minHeight:'16px'}, prop.style);
            dStyle.border = `${borderSize}px solid ${borderColor}`;
            dStyle.background=`${bgColor}`
            dStyle.left=parseInt(dStyle.left)*0.75;
            dStyle.width=parseInt(dStyle.width)*0.75;
            dStyle.top=parseInt(dStyle.top)
            return (
                <div
                    key={key}
                    style={dStyle}
                    id={prop.id}
                    data-element={prop.name}
                    data-section={"head"}
                    data-path="shapes"
                    data-index={prop.data}
                >

                </div>
            );
        });

      };

    
    return (


                            <div style={{ height: props.properties.prop.orientation==="landscape" ? props.heights.head * 0.01 * pageSettings[props.properties.prop.paperSize].width : props.heights.head * 0.01 * pageSettings[props.properties.prop.paperSize].height }}>
                                <div
                                // style={{
                                //     position: "absolute",
                                // }}
                                >
                                    {renderElements("head")}
                                    {renderImage("head")}
                                    {renderDocumentTitle()}
                                    {renderCompanyElements()}
                                    {renderShapes()}
                                </div>
                            </div>


    )
}
