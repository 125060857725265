import React, { Component } from "react";
import PropTypes from "prop-types";

import GridLines from "react-gridlines";

class Ruler extends Component {
  constructor(props) {
    super(props);
    this._$container = React.createRef();
    this._$corner = React.createRef();
    this._$topRuler = React.createRef();
    this._$leftRuler = React.createRef();
    this._$topArrow = React.createRef();
    this._$leftArrow = React.createRef();
    this._$stage = React.createRef();
    this._$measure = React.createRef();

    this._scrollTop = 0;
    this._scrollLeft = 0;

    this._scrollBarWidth = 0;
    this._unitDiv = 1.0;

    this._lastTopRulerPos = 0;
    this._lastLeftRulerPos = 0;
    /**
     * px - pixel
     * mm - millimeter
     * cm - centimeter
     * in - inch
     */
    this._units = ["px", "mm", "cm", "in"];

    this._xCaliber = 0;
    this._yCaliber = 0;

    this.state = {
      unit: props.unit !== undefined ? this._constrainUnit(props.unit) : "mm",
      tickMajor:
        props.tickMajor !== undefined
          ? this._constrainUnit(props.tickMajor)
          : 10,
      tickMinor:
        props.tickMinor !== undefined
          ? this._constrainUnit(props.tickMinor)
          : 5,
      tickMicro:
        props.tickMicro !== undefined ? this._constrainUnit(props.unit) : 1,
      showLabel: props.showLabel !== undefined ? props.showLabel : true,
      arrowStyle: props.arrowStyle !== undefined ? props.arrowStyle : "line",
      startX:
        props.startX !== undefined ? this._constrainUnit(props.startX) : 0,
      startY:
        props.startY !== undefined ? this._constrainUnit(props.startY) : 0,
      canvesWidth: props.canvesWidth !== undefined ? props.canvesWidth : 210,
      canvesHeight: props.canvesHeight !== undefined ? props.canvesHeight : 297,
      showPageGridLines:
        props.showPageGridLines !== undefined ? props.showPageGridLines : true,
      showGridLines:
        props.showGridLines !== undefined ? props.showGridLines : true,
      showPointer: props.showPointer !== undefined ? props.showPointer : true,

      pointerX: 0,
      pointerY: 0,
      cordX: 0,
      cordY: 0,

      height: props.height !== undefined ? props.height : 0,
      width: props.width !== undefined ? props.width : 0,

      topElements: [],
      leftElements: [],

      gridLines: [],
      pageHeight: 0,

      pointerInside: false,
    };

    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this._handleTopRulerclick = this._handleTopRulerclick.bind(this);
    this._handleLeftRulerclick = this._handleLeftRulerclick.bind(this);
    this._gridLineMouseDown = this._gridLineMouseDown.bind(this);
    this._gridLineMouseMove = this._gridLineMouseMove.bind(this);
    this._gridLineMouseUp = this._gridLineMouseUp.bind(this);
    this._gridLineMouseLeave = this._gridLineMouseLeave.bind(this);
  }

  componentDidMount() {
    var h = this.state.canvesHeight * this._calcPixelsPerMM() + 140;
    this.setState(
      {
        pageHeight: h,
      },
      function () {
        this.create();
      }
    );

    console.log("1mm = " + this._calcPixelsPerMM() + "px");
  }

  handleMouseMove = function (e) {
    let currentTargetRect = e.currentTarget.getBoundingClientRect();
    var arrowX = e.clientX - currentTargetRect.left - this._$corner.offsetWidth;
    var arrowY = e.clientY - currentTargetRect.top - this._$corner.offsetHeight;

    this.setState({
      pointerX: arrowX,
      pointerY: arrowY,
      cordX: parseInt((arrowX - this._xCaliber) / this._unitDiv),
      cordY: parseInt((arrowY - this._yCaliber) / this._unitDiv),
      pointerInside: true,
    });

    //console.log(this.props);
  };

  handleMouseLeave = function (e) {
    this.setState({ pointerInside: false });
  };

  create = function () {
    this._setUnitDiv();
    this._updateRulerTicks();
  };

  // #region Grid Line Events
  _handleTopRulerclick = function (e) {
    this._rulerClick(e, "x");
  };

  _handleLeftRulerclick = function (e) {
    this._rulerClick(e, "y");
  };

  _rulerClick = function (e, axis) {
    var ele = {
      mill: new Date().getTime(),
      axis: axis,
      left: axis === "x" ? 0 : this._xCaliber + 10,
      top: axis === "y" ? 0 : this._yCaliber + 10,
      flag: false,
      pos: "0 mm",
    };

    var arr = this.state.gridLines.concat(ele);
    this.setState({ gridLines: arr });
  };

  _gridLineMouseDown = function (e, obj) {
    e.preventDefault();
    var e = e || window.event;
    obj.flag = true;

    var arr = this.state.gridLines;
    this.setState({ gridLines: arr });
  };

  _gridLineMouseMove = function (e, obj) {
    if (obj.flag) {
      if (
        (obj.axis === "y" && this.state.cordX < 0) ||
        (obj.axis === "x" && this.state.cordY < 0)
      ) {
        for (var i = 0; i < this.state.gridLines.length; i++) {
          if (this.state.gridLines[i].mill === obj.mill) {
            this.state.gridLines.splice(i, 1);
            var arr = this.state.gridLines;
            this.setState({ gridLines: arr });
            return false;
          }
        }
        return;
      }

      if (obj.axis === "y") {
        obj.left = parseInt(this.state.pointerX);
        obj.pos = this.state.cordX + "mm";
      } else if (obj.axis === "x") {
        obj.top = parseInt(this.state.pointerY);
        obj.pos = this.state.cordY + "mm";
      }
    }
  };

  _gridLineMouseUp = function (e, obj) {
    obj.flag = false;
    var arr = this.state.gridLines;
    this.setState({ gridLines: arr });
  };

  _gridLineMouseLeave = function (e, obj) {
    obj.flag = false;
  };

  // #endregion

  // #region Ruler Events
  _updateRulerTicks = function () {
    var topElements = [];
    var leftElements = [];

    var unitPos = 0;

    /* Top ruler */
    unitPos = 0;
    var topRulerWidth = this._$topRuler.offsetWidth - this._$corner.offsetWidth;
    var canvesWidthPixels = this.state.canvesWidth * this._unitDiv;
    var xBalance = (topRulerWidth - canvesWidthPixels) / 2;
    this._xCaliber = parseInt(xBalance);
    this._lastTopRulerPos = this._xCaliber;

    while (
      this._lastTopRulerPos < topRulerWidth &&
      unitPos <= this.state.canvesWidth
    ) {
      if (this.state.tickMajor > 0 && unitPos % this.state.tickMajor === 0) {
        topElements.push({
          clss: "major",
          lpos: this._lastTopRulerPos,
          upos: unitPos,
        });
      } else if (
        this.state.tickMinor > 0 &&
        unitPos % this.state.tickMinor === 0
      ) {
        topElements.push({
          clss: "minor",
          lpos: this._lastTopRulerPos,
          upos: unitPos,
        });
      } else if (
        this.state.tickMicro > 0 &&
        unitPos % this.state.tickMicro === 0
      ) {
        topElements.push({
          clss: "micro",
          lpos: this._lastTopRulerPos,
          upos: unitPos,
        });
      }

      this._lastTopRulerPos += this._unitDiv;
      unitPos++;
    }

    /* Left ruler */

    unitPos = 0;

    var leftRulerHeight = this.state.pageHeight - this._$corner.offsetWidth * 2;
    var canvesHeightPixels = this.state.canvesHeight * this._unitDiv;
    var yBalance = (leftRulerHeight - canvesHeightPixels) / 2;
    this._yCaliber = parseInt(yBalance);
    this._lastLeftRulerPos = this._yCaliber;

    while (
      this._lastLeftRulerPos < leftRulerHeight &&
      unitPos <= this.state.canvesHeight
    ) {
      if (this.state.tickMajor > 0 && unitPos % this.state.tickMajor === 0) {
        leftElements.push({
          clss: "major",
          tpos: this._lastLeftRulerPos,
          upos: unitPos,
        });
      } else if (
        this.state.tickMinor > 0 &&
        unitPos % this.state.tickMinor === 0
      ) {
        leftElements.push({
          clss: "minor",
          tpos: this._lastLeftRulerPos,
          upos: unitPos,
        });
      } else if (
        this.state.tickMicro > 0 &&
        unitPos % this.state.tickMicro === 0
      ) {
        leftElements.push({
          clss: "micro",
          tpos: this._lastLeftRulerPos,
          upos: unitPos,
        });
      }

      this._lastLeftRulerPos += this._unitDiv;
      unitPos++;
    }

    this.setState({
      topElements: topElements,
      leftElements: leftElements,
    });
  };

  _setUnitDiv = function () {
    switch (this.state.unit) {
      case "px":
        this._unitDiv = 1;
        break;
      case "mm":
        this._unitDiv = this._calcPixelsPerMM();
        break;
      case "cm":
        this._unitDiv = this._calcPixelsPerMM() * 10;
        break;
      case "in":
        this._unitDiv = this._calcPixelsPerMM() * 25.4;
        break;
      default:
        this._unitDiv = 1;
        break;
    }

    this.props.setMeasureSpec({
      px: 1,
      mm: this._calcPixelsPerMM(),
      cm: this._calcPixelsPerMM() * 10,
      in: this._calcPixelsPerMM() * 25.4,
    });
  };

  _calcPixelsPerMM = function () {
    var rect = this._$measure.getBoundingClientRect();
    return rect.width;
  };

  _constrainUnit = function (unit) {
    if (typeof unit === "string") {
      unit = unit.toLowerCase();
      this._units.every(function (sUnit) {
        if (unit === sUnit) return false;

        return true;
      });
    } else {
      unit = "px";
    }

    return unit;
  };
  // #endregion

  render() {
    const element = this;
    return (
      <div
        ref={(ref) => (this._$container = ref)}
        className="ef-ruler"
        id="ef-ruler"
        onMouseMove={this.handleMouseMove}
        onMouseLeave={this.handleMouseLeave}
        style={{ height: this.state.pageHeight }}
      >
        <div ref={(ref) => (this._$corner = ref)} className="corner"></div>

        <div
          ref={(ref) => (this._$topRuler = ref)}
          className="ruler top"
          onClick={this._handleTopRulerclick}
        >
          <div
            ref={(ref) => (this._$topArrow = ref)}
            className={"top-" + this.state.arrowStyle}
            style={{ left: this.state.pointerX }}
          ></div>

          {this.state.topElements.map(function (e, k) {
            return (
              <div
                key={k}
                className={"tick " + e.clss}
                style={{ left: e.lpos }}
              >
                {element.state.showLabel && e.clss === "major" ? e.upos : ""}
              </div>
            );
          })}
        </div>

        <div
          ref={this._$leftRuler}
          className="ruler left"
          onClick={this._handleLeftRulerclick}
        >
          <div
            ref={(ref) => (this._$leftArrow = ref)}
            className={"left-" + this.state.arrowStyle}
            style={{ top: this.state.pointerY }}
          ></div>
          {this.state.leftElements.map(function (e, k) {
            return (
              <div key={k} className={"tick " + e.clss} style={{ top: e.tpos }}>
                {element.state.showLabel && e.clss === "major" ? e.upos : ""}
              </div>
            );
          })}
        </div>

        <div
          className="grid-area-wrapper"
          style={{ display: this.props.showPageGridLines ? "block" : "none" }}
        >
          <div
            className="grid-area"
            style={{ height: "297mm", width: "250mm" }}
          >
            <GridLines
              cellWidth={this._unitDiv * 20}
              strokeWidth={3}
              cellWidth2={this._unitDiv * 2}
              strokeWidth2={1}
            ></GridLines>
          </div>
        </div>

        {this.props.children}

        <div
          className="grid-wrapper"
          style={{ display: this.props.showGridLines ? "block" : "none" }}
        >
          {this.state.gridLines.map(function (obj, k) {
            return (
              <div
                key={k}
                onMouseDown={(e) => element._gridLineMouseDown(e, obj)}
                onMouseMove={(e) => element._gridLineMouseMove(e, obj)}
                onMouseUp={(e) => element._gridLineMouseUp(e, obj)}
                onMouseLeave={(e) => element._gridLineMouseLeave(e, obj)}
                className={
                  "grid-line grid-line-" +
                  obj.axis +
                  (obj.flag ? " dragging-line" : "")
                }
                id={"grid-line-" + obj.axis + obj.mill}
                data-axis={obj.axis}
                style={{ top: obj.top, left: obj.left }}
              >
                <div className={"grid-line-pad-" + obj.axis}></div>
                <div className="info">
                  <span>{obj.pos}</span>
                </div>
              </div>
            );
          })}
        </div>

        {this.state.pointerInside ? (
          <div style={{ display: this.props.showPointer ? "block" : "none" }}>
            <div
              className="v-mouse"
              style={{ top: this.state.pointerY + 18 }}
            ></div>
            <div
              className="h-mouse"
              style={{ left: this.state.pointerX + 18 }}
            ></div>
            <div
              className="mouse-pos-box"
              style={{
                left: this.state.pointerX + 25,
                top: this.state.pointerY + 25,
              }}
            >
              {"x: " +
                parseInt(this.state.cordX) +
                "mm, y:" +
                parseInt(this.state.cordY) +
                "mm"}
            </div>
          </div>
        ) : (
          <div></div>
        )}

        <div
          ref={(ref) => (this._$measure = ref)}
          style={{
            position: "absolute",
            top: "-999px",
            left: "-999px",
            width: "1mm",
            height: "1mm",
            overflow: "hidden",
          }}
        ></div>
      </div>
    );
  }
}

export default Ruler;

Ruler.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};
