import React from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";

class ColorPicker extends React.Component {
  constructor(props) {
    super(props);
    var hex = props.color;
    var rgb = this.hexToRgba(hex);
    this.state = {
      displayColorPicker: false,
      color: {
        r: rgb ? rgb.r : 255,
        g: rgb ? rgb.g : 255,
        b: rgb ? rgb.b : 255,
        a: rgb ? rgb.a : 1,
      },
    };
  }

  componentToHex = function (c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  };

  rgbaToHex = function ({ r, g, b, a }) {
    return (
      "#" +
      this.componentToHex(r) +
      this.componentToHex(g) +
      this.componentToHex(b) +
      Math.round(a * 255)
        .toString(16)
        .substring(0, 2)
    );
  };

  hexToRgba = function (hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
      hex
    );
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
          a: parseInt(result[4], 16),
        }
      : null;
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    //console.log();
    color.hex = this.rgbaToHex(color.rgb);
    this.setState({ color: color.rgb }, function () {
      this.props.callbackFromParents(color);
    });
  };

  render() {
    const styles = reactCSS({
      default: {
        color: {
          width: "32px",
          height: "100%",
          border: "1px solid #CCC",
          background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
        },
        swatch: {
          background: "#fff",
          display: "inline-block",
          cursor: "pointer",
          display: "flex",
          height: "100%",
        },
        popover: {
          position: "absolute",
          zIndex: "100000",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });

    return (
      <>
        <div style={styles.swatch} onClick={this.handleClick}>
          <div style={styles.color} />
        </div>
        {this.state.displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <SketchPicker
              color={this.state.color}
              onChange={this.handleChange}
            />
          </div>
        ) : null}
      </>
    );
  }
}

export default ColorPicker;
