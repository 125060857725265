import { TemplateActionTypes } from "../constants/actionTypes";
import { PAPER_SIZE, ORIENTATION } from "../../com/lib/config/enums";

const initialState = {
  selectedElement: {
    section: "",
    path: "",
    index: "",
    element: null,
  },
  heights: {
    head: 20,
    body: 60,
    total: 10,
    foot: 10,
  },
  prop: {
    templateName: "Template 1",
    isPriceChangeNotification: false,
    priceNotificationColour: {
      backgroundColour: "#FFFFFFFF",
      foreColour: "#333333FF",
    },
    isWaterMark: false,
    waterMarkText: "",
    paperSize: PAPER_SIZE.A4,
    orientation: ORIENTATION.PORTRAIT,
    margin: { top: 0.5, right: 0.5, left: 0.5, bottom: 0.5 },
    background: {
      showColor: false,
      color: "#FFFFFFFF",
    },
    labelColor: "#333333FF",
    fontColor: "#333333FF",
    fontSize: 8,
  },
  head: {
    printingHeaderEveryPage: true,
    logo: {
      name: "",
      showLogo: false,
      logo: null,
      logoSize: 100,
      style: {
        zIndex: 99
      },
    },
    companyProps: {},
    documentTitle: {
      show: false,
      title: "INVOICE",
      id: "doc-title",
      // style: {}
      style: {
        position: "relative",
        width: "60%",
        fontWeight: "bold",
        fontSize: 20,
        color: "#333333FF",
        paddingTop: "10px",
        paddingRight: "0",
        paddingBottom: "0px",
        paddingLeft: "0px",
        textAlign: "center",
      },
    },
    background: {
      showColor: false,
      color: "#FFFFFFFF",
    },
    font: {
      fontFamily:'Arial',
      fontSize: 10,
      color: '#000000FF'
    },
    elements: {},
    shapes:{
      border: {
        color: '#333333FF',
        size: 1,
        fillColor: '#FFFFFFFF'
      }
    }
  },
  items: {
    defenition: "",
    layout: {
      tableBorder: "#ADADADFF",
      tableHeader: {
        fontSize: 9,
        fontFamily: "Arial",
        backgroundColor: "#555555",
        fontColor: "#ffffffff",
        paddingTop: "5px",
        paddingRight: "0",
        paddingBottom: "5px",
        paddingLeft: "5px",
        textAlign: "left",
      },
      itemRow: {
        fontSize: 9,
        fontFamily: "Arial",
        backgroundColor: "#FFFFFFFF",
        fontColor: "#333333FF",
        paddingTop: "10px",
        paddingRight: "0",
        paddingBottom: "10px",
        paddingLeft: "5px",
        textAlign: "left",
        maxRowCount: 20,
        showHorizontalLines: false,
        horizontalLineColor: '#eeeeeeff',
        horizontalLineSize: 1,
        showVerticleLines: false,
        verticleLineColor: '#eeeeeeff',
        verticleLineSize: 1
      },
      itemDescription: {
        fontSize: 8,
        backgroundColor: "#FFFFFFFF",
        fontColor: "#3c3d3aFF",
        paddingTop: "5px",
        paddingRight: "0",
        paddingBottom: "10px",
        paddingLeft: "20px",
        textAlign: "left",
      },
    },
    items: {},
  },
  total: {
    background: {
      showColor: false,
      color: "#FFFFFFFF",
    },
    elements: {},
    VatSummary: {},
    font: {
      fontFamily:'Arial',
      fontSize: 10,
      color: '#000000FF'
    },
    shapes:{
      border: {
        color: '#333333FF',
        size: 1,
        fillColor: '#FFFFFFFF'
      }
    }
  },
  foot: {
    background: {
      showColor: false,
      color: "#FFFFFFFF",
    },
    elements: {},
    font: {
      fontFamily:'Arial',
      fontSize: 10,
      color: '#000000FF'
    },
    shapes:{
      border: {
        color: '#333333FF',
        size: 1,
        fillColor: '#FFFFFFFF'
      }
    }
  },
};

export const templateReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case TemplateActionTypes.UPDATE_PROPS:
      return {
        ...state,
        prop: data,
      };

    case TemplateActionTypes.UPDATE_HEAD:
      var ele = {
        ...state,
        head: data,
      };
      return ele;

    case TemplateActionTypes.UPDATE_ITEMS:
      return {
        ...state,
        items: data,
      };

    case TemplateActionTypes.UPDATE_TOTAL:
      return {
        ...state,
        total: data,
      };

    case TemplateActionTypes.UPDATE_FOOT:
      return {
        ...state,
        foot: data,
      };

    case TemplateActionTypes.SET_SELECTED_ELEMENT:
      return {
        ...state,
        selectedElement: {
          section: data.section,
          path: data.path,
          index: data.index,
          element: data.element,
        },
      };

    case TemplateActionTypes.MOVE_ELEMENT:
      let _s1 = state.selectedElement.section;
      let _p1 = state.selectedElement.path;
      let _i1 = state.selectedElement.index;
      var _el1 =
        _i1 === "" || _p1 === "documentTitle" || _p1 === "VatSummary"
          ? {
              ...state,
              [_s1]: {
                ...state[_s1],
                [_p1]: {
                  ...state[_s1][_p1],
                  style: {
                    ...state[_s1][_p1].style,
                    top: data.top + "px",
                    left: data.left + "px",
                  },
                },
              },
            }
          : {
              ...state,
              [_s1]: {
                ...state[_s1],
                [_p1]: {
                  ...state[_s1][_p1],
                  [_i1]: {
                    ...state[_s1][_p1][_i1],
                    ...state[_s1][_p1][_i1] ? {
                    style: {
                      ...state[_s1][_p1][_i1].style,
                      top: data.top + "px",
                      left: data.left + "px",
                    },
                    }:''
                  },
                },
              },
            };
      return _el1;

    case TemplateActionTypes.RESIZE_ELEMENT:
      let _s3 = state.selectedElement.section;
      let _p3 = state.selectedElement.path;
      let _i3 = state.selectedElement.index;

      var _el3 =
        (_i3 === "" || _p3 === "documentTitle" || _p3 === "VatSummary")
          ? {
              ...state,
              [_s3]: {
                ...state[_s3],
                [_p3]: {
                  ...state[_s3][_p3],
                  style: {
                    ...state[_s3][_p3].style,
                    height: data.height + "px",
                    width: data.width + "px",
                  },
                },
              },
            }
          : _i3 === "Address" || _i3 === "LocationAddress"?
          {
            ...state,
            [_s3]: {
              ...state[_s3],
              [_p3]: {
                ...state[_s3][_p3],
                [_i3]: {
                  ...state[_s3][_p3][_i3],

                  ...state[_s3][_p3][_i3] ? {
                  style: {
                    ...state[_s3][_p3][_i3].style,
                    height: data.height + "px",
                    width: data.width + "px",
                  },
                  allowMultiLine: data.height/24 >1
                }:''
  
                },
              },
            },
          }
          :{
              ...state,
              [_s3]: {
                ...state[_s3],
                [_p3]: {
                  ...state[_s3][_p3],
                  [_i3]: {
                    ...state[_s3][_p3][_i3],

                    ...state[_s3][_p3][_i3] ? {
                    style: {
                      ...state[_s3][_p3][_i3].style,
                      height: data.height + "px",
                      width: data.width + "px",
                    },
                    allowMultiLine: false
                  }:''
    
                  },
                },
              },
            };
      return _el3;

    case TemplateActionTypes.UPDATE_ELEMENT:
      let _s2 = state.selectedElement.section;
      let _p2 = state.selectedElement.path;
      let _i2 = state.selectedElement.index;
      let element = data.element;

      // return {
      //   ...state,
      //   [_s2]: {
      //     ...state[_s2],
      //     [_p2]: {},
      //   },
      // };
    
      return {
        ...state,
        [_s2]: {
          ...state[_s2],
          [_p2]:
            state[_s2][_p1].length > 0
              ? [
                  ...state[_s2][_p1].slice(0, _i2),
                  element,
                  ...state[_s2][_p1].slice(_i2 + 1),
                ]
              : [element],
        },
      };

    case TemplateActionTypes.SET_SECTION_HEIGHT:
      return {
        ...state,
        heights: {
          head: data.head,
          body: data.body,
          total: data.total,
          foot: data.foot,
        },
      };
    // case TemplateActionTypes.UPDATE_LOGO:
    //   return {
    //     ...state,
    //     [data.section]: {
    //       ...[data.section],
    //       logo: data.logo,
    //     },
    //   };

    case TemplateActionTypes.UPDATE_LOGO:
      return {
        ...state,
        head: {
          ...state.head,
          logo: {
            name: "",
            showLogo: true,
            logo: data.logo,
            logoSize: 100,
            style: {
              position: "absolute",
              top: "16px",
              left: "16px",
              zIndex: 99
            }
          },
        },
      };

    case TemplateActionTypes.SET_DATA:
      console.log("state ====>>", state)
      console.log("TemplateActionTypes.SET_DATA===>>" , data);
      return {
        ...state,
        selectedElement: data.selectedElement,
        heights: data.heights,
        prop: data.prop,
        head: {...state.head, ...data.head},
        items: {...state.items, ...data.items},
        total: {...state.total, ...data.total},
        foot: {...state.foot, ...data.foot},
      };

    case TemplateActionTypes.RESET_DATA:
      return initialState;

    case TemplateActionTypes.REMOVE_HEADER_IMAGE:
      return {
        ...state,
        head: {
          ...state.head,
          logo: {
            name: "",
            showLogo: false,
            logo: null,
            logoSize: 100,
            style: {},
          },
        }

      }
    
    case TemplateActionTypes.UPDATE_SPLIT_SIZES:
      // console.log("DATA_", data)
      return {
        ...state,
        heights: {
          head: data[0],
          body:  data[1],
          total:  data[2],
          foot:  data[3],
        }
      }

    default:
      return state;
  }
};
