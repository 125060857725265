import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Loader from "react-loader-spinner";
import ReactJson from "react-json-view";

import { saveAs } from "file-saver";

//TODO: use this funtion to call the print server
import { PrintTemplate } from "../../lib/api/data/PDF";
import PATHS from "../../../json/paths.json";
import { formatDate } from "../../lib/global/helpers";

import {
  Row,
  Col,
  Button,
  Dropdown,
  Modal,
  Form,
  InputGroup,
  ListGroup,
  FormControl,
} from "react-bootstrap";
import moment from "moment";

import { SearchOrder } from "../../lib/api/data/Order";
import { GetAllTemplates } from "../../lib/api/data/Request";
import { setSelectedTemplateType } from "../../../redux/actions/CurrentActions";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlus,
  faSearch,
  faEye,
  faEyeSlash,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(faPlus, faSearch, faEye, faEyeSlash, faPrint);

class Index extends Component {
  constructor(props) {
    super(props);
    this.showEditorCallBack = this.showEditorCallBack.bind(this);
    this.state = {
      isLoading: true,
      showAlert: true,
      showPopup: false,
      templates: [],
      selectedPOS: "epos",
      selectedTemplateType: "invoicetemplate",
      selectedTemplateDataType: "invoicedata",
      selectedTemplate: null,
      selectedTemplateData: null,
      selectedTemplateKey: -1,
      wizardLevel: 0,
      currentOrderID: "",
      //showJSON: false,
    };

    this._printTemplate = this._printTemplate.bind(this);
    this._searchOrderID = this._searchOrderID.bind(this);
  }

  componentDidMount() {
    this._fetchData(this.state.selectedTemplateType);
  }

  _fetchData = (templateType) => {
    this.props.setSelectedTemplateType(templateType);
    this.setState(
      { templates: [], isLoading: true, selectedTemplateType: templateType },
      function () {
        GetAllTemplates().then((response) => {
          if (response.isSuccess) {
            //console.log(response.data.Result);
            this.setState(
              { templates: response.data.Result, isLoading: false },
              function () {
                this.props.setSelectedTemplateType("");
                //console.log(this.state.templates);
              }
            );
          }
        });
      }
    );
  };

  _selectTempate = (template, key) => {
    this.setState({
      selectedTemplate: template,
      selectedTemplateKey: key,
      wizardLevel: 1,
    });
  };

  showEditorCallBack = (e, templateType) => {
    this.setState({ showPopup: true });
  };

  _renderSection1 = () => {
    return (
      <section id="model-section-0">
        <InputGroup>
          <select
            className="form-select form-control"
            aria-label="Default select"
            onChange={(e) => {
              var value = e.target.value;
              this._fetchData(value);
            }}
          >
            <option style={{ padding: "5px 0" }} value="invoicetemplate">
              Invoice Template
            </option>
            <option
              style={{ padding: "5px 0" }}
              value="performainvoicetemplate"
            >
              Proforma Invoice Template
            </option>
            <option style={{ padding: "5px 0" }} value="deliverynotetemplate">
              Delivery Note Template
            </option>
          </select>
          <InputGroup.Text>
            <Button variant="link">
              <FontAwesomeIcon icon="plus" />
            </Button>
          </InputGroup.Text>
        </InputGroup>
        <hr />
        {this.state.templates && (
          <Form.Group>
            <ListGroup
              className="mb-3"
              style={{
                maxHeight: "300px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              {this.state.templates.map((template, key) => {
                {
                  const isSelected = this.state.selectedTemplateKey === key;
                  return (
                    <ListGroup.Item
                      key={key}
                      className={
                        "d-flex w-100 justify-content-between py-3 " +
                        (isSelected ? "bg-primary text-white" : "bg-white")
                      }
                      onClick={(e) => this._selectTempate(template, key)}
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        <h5 className="my-0">{template.TemplateName}</h5>
                        <small
                          className={
                            "small " +
                            (isSelected ? "bg-primary text-white" : "bg-white")
                          }
                        >
                          {"Updated " + moment(template.ModifiedDate).fromNow()}
                        </small>
                      </div>
                      {template.IsDefault ? (
                        <FontAwesomeIcon
                          icon="check"
                          color={isSelected ? "white" : "green"}
                          className="ms-auto mt-3"
                        />
                      ) : (
                        <></>
                      )}
                    </ListGroup.Item>
                  );
                }
              })}
            </ListGroup>
          </Form.Group>
        )}
      </section>
    );
  };

  _renderSection2 = () => {
    return (
      <section id="model-section-1" className="pb-4">
        <Form>
          <Form.Group controlId="forFontSize">
            <InputGroup style={{ width: "450px", margin: "auto" }}>
              <InputGroup.Text className="bg-white border-0">
                Order ID
              </InputGroup.Text>
              <FormControl
                value={this.state.currentOrderID}
                placeholder="Enter order id here..."
                onChange={(e) =>
                  this.setState({ currentOrderID: e.target.value })
                }
                style={{
                  borderTop: 0,
                  borderRight: 0,
                  borderLeft: 0,
                  borderBottom: "1px solid #CCC",
                }}
              />
              <InputGroup.Text className="bg-white border-0">
                <Button variant="link" onClick={(e) => this._searchOrderID(e)}>
                  <FontAwesomeIcon icon="search" />
                </Button>
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          {this.state.selectedTemplateData ? (
            <div className="row">
              <div className="col">
                <div className="invoice-card one">
                  <div className="top">
                    <div className="wrapper">
                      <h3 className="heading">Invoice Found</h3>
                    </div>
                  </div>
                  <div className="bottom">
                    <div className="wrapper">
                      {/*}
                      <Button
                        variant="link"
                        onClick={(e) => {
                          this.setState({ showJSON: !this.state.showJSON });
                        }}
                      >
                        <FontAwesomeIcon
                          icon={this.state.showJSON ? "eye-slash" : "eye"}
                          title="View Data"
                        />
                      </Button>
                  */}
                      <Button
                        variant="link"
                        onClick={(e) => {
                          this.setState({
                            wizardLevel: 2,
                          });
                        }}
                      >
                        <FontAwesomeIcon icon="print" title="Print Template" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/*
          {this.state.showJSON ? (
            <>
              <hr className="my-4" />
              <div
                style={{
                  overflow: "hidden",
                  height: "250px",
                  overflowX: "hidden",
                  overflowY: "scroll",
                }}
              >
                <ReactJson src={this.state.selectedTemplateData} />
              </div>
            </>
          ) : (
            <></>
          )}
          */}
        </Form>
      </section>
    );
  };

  _renderSection3 = () => {
    return (
      <section id="model-section-2" className="mb-3">
        <div className="d-flex">
          <Button
            variant="primary"
            size="lg"
            className="me-auto ms-auto px-5"
            onClick={(e) => this._printTemplate(e)}
          >
            <FontAwesomeIcon icon="print" color="white" className="me-2" />
            Print document now
          </Button>
        </div>
      </section>
    );
  };

  _searchOrderID = (e) => {
    this.setState({ isLoading: true }, function () {
      SearchOrder(
        this.state.selectedPOS,
        this.state.selectedTemplateDataType,
        this.state.currentOrderID
      ).then((response) => {
        if (response.isSuccess) {
          var data = response.data.Result;
          this.setState({ selectedTemplateData: data, isLoading: false });
        } else {
          this.setState({ selectedTemplateData: null, isLoading: false });
        }
      });
    });
  };

  _printTemplate = (e) => {
    const pos = this.state.selectedPOS,
      templateType = this.state.selectedTemplateType,
      dataTemplate = this.state.selectedTemplateDataType,
      cloudSalesOrderID = this.state.currentOrderID,
      templateID = this.state.selectedTemplate.ID;

    const url = PATHS.Print;

    const fileName = `${cloudSalesOrderID}(${new Date().getTime()}).pdf`;

    const data = {
      pos,
      templateType,
      dataTemplate,
      templateID,
      cloudSalesOrderID,
      cloudLocationID: this.props.cloudLocationID,
      orgID: this.props.orgID,
    };

    const config = {
      responseType: "blob",
    };

    //TODO: Use generic function => PrintTemplate(pos, templateName, cloudSalesOrderID);
    axios
      .post(url, data, config)
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        const file = saveAs(pdfBlob, fileName);
      })
      .then((res) => {
        //TODO: Delte PDF
        //console.log("delete PDF");
      });
  };

  render() {
    var templateType = this.props.match.params.type;

    return (
      <>
        <div className="list">
          <div className="list-content mt-5">
            <div className="intro text-center pt-4">
              <h3>Lets print a document!</h3>
              <p className="text-muted">
                Print the documents with the templates you have saved earlier.
              </p>
              <div style={{ marginTop: "25px" }} className="cb">
                <div className="btn-group dropdown">
                  <Dropdown>
                    <Dropdown.Toggle
                      className="me-3"
                      variant="primary"
                      style={{ padding: "10px 24px" }}
                    >
                      <FontAwesomeIcon icon="plus" color="white" />
                      <span className="mx-2">New Invoice</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          this.setState({
                            selectedTemplateType: "invoicetemplate",
                            selectedTemplateDataType: "invoicedata",
                            selectedTemplate: null,
                            selectedTemplateData: null,
                            selectedTemplateKey: -1,
                            currentOrderID: "",
                            showJSON: false,
                            showAlert: true,
                            wizardLevel: 0,
                          });
                        }}
                      >
                        Invoice
                      </Dropdown.Item>
                      <Dropdown.Item>Proforma Invoice</Dropdown.Item>
                      <Dropdown.Item>Delivery Note</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <Link
                  className="btn btn-secondary uppercase"
                  style={{ padding: "10px 24px" }}
                  to="/templates"
                >
                  Create a new template
                </Link>
              </div>
              <Link
                className="d-block"
                style={{ marginTop: "14px" }}
                to="/templates/import"
              >
                Import Invoices
              </Link>
            </div>
          </div>
        </div>

        <Modal
          show={this.state.showAlert}
          {...this.props}
          style={{ zIndex: 10600, backgroundColor: "#484848b3" }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={(e) => {
            this.setState({ showAlert: false });
          }}
        >
          <Modal.Body>
            <Form>
              <div className="modal-wizard">
                <h3 className="text-center w-100 mt-4">
                  Print from a Template
                </h3>
                <ul className="modal-wizard_actions">
                  {["Select Template", "Find Order", "Print Order"].map(
                    (item, i) => {
                      return (
                        <li
                          className={
                            this.state.wizardLevel === i ? "checked" : ""
                          }
                          key={`tmp_action_${i}`}
                        >
                          <a>
                            <span className="number">{i}</span>
                          </a>
                          <span>{item}</span>
                        </li>
                      );
                    }
                  )}
                </ul>
                <div className="model-wizard_body">
                  {this.state.isLoading ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "100%" }}
                    >
                      <Loader
                        type="Rings"
                        color="#CCCCCC"
                        height={100}
                        width={100}
                      />
                    </div>
                  ) : this.state.wizardLevel === 0 ? (
                    this._renderSection1()
                  ) : this.state.wizardLevel === 1 ? (
                    this._renderSection2()
                  ) : (
                    this._renderSection3()
                  )}
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedTemplateType: state.current.selectedTemplateType,
    selectedPOS: state.current.selectedPOS,
    orgID: sessionStorage.getItem('orgID'),
    cloudLocationID: sessionStorage.getItem('LocationID'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedTemplateType: (e) => dispatch(setSelectedTemplateType(e)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Index));
