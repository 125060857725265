import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { saveAs } from "file-saver";

import { PrintTemplate } from "../../lib/api/data/PDF";
import PATHS from "../../../json/paths.json";
import { formatDate } from "../../lib/global/helpers";

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pos: "epos",
      templateType: "invoicetemplate",
      dataTemplate: "invoicedata",
      templateID: "60d9b2fcd671f3bc6f4c09a5",
      cloudSalesOrderID: "123",
    };

    this.createAndDownloadPdf = this.createAndDownloadPdf.bind(this);
  }

  createAndDownloadPdf = () => {
    const { pos, templateType, dataTemplate, cloudSalesOrderID, templateID } =
      this.state;
    const url = PATHS.Print;

    const fileName = `${new Date().getTime()}.pdf`;

    const data = {
      pos,
      templateType,
      dataTemplate,
      templateID,
      cloudSalesOrderID,
      cloudLocationID: this.props.cloudLocationID,
      orgID: this.props.orgID,
    };

    const config = {
      responseType: "blob",
    };

    //TODO: Use generic function => PrintTemplate(pos, templateName, cloudSalesOrderID);
    axios
      .post(url, data, config)
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        const file = saveAs(pdfBlob, fileName);
      })
      .then((res) => {
        //console.log("delete PDF");
      });
  };

  render() {
    return (
      <div className="App">
        <button onClick={this.createAndDownloadPdf}>Download PDF</button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orgID: sessionStorage.getItem('orgID'),
    cloudLocationID: sessionStorage.getItem('LocationID'),
  };
};

const mapDispatchToProps = (dispatch) => {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Index));
