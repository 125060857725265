import axios from "axios";
import { getJWT } from "../global/helpers";
import AxiosMessage from "./AxiosMessage";
import store from "../../../redux/store";
import jwt from 'jsonwebtoken';

const { REACT_APP_webneurolagejwtAud, REACT_APP_webneurolagejwtIss, REACT_APP_webneurolagejwtPrivate } = process.env;

export async function axiosCall(method, url, config, data, usetoken) {
  if (usetoken) {
    let JWT = getJWT();
    if (JWT.token !== null) {
      config["Authorization"] = "bearer " + JWT.token;
    }
  }


  //console.log(data)

  const timestamp = Math.floor(Date.now() / 1000) + 1000;
  var payload = {
    "aud": REACT_APP_webneurolagejwtAud,
    "exp": timestamp,
    "iss": REACT_APP_webneurolagejwtIss
  };
  let usertoken=sessionStorage.getItem('accessToken')
  if (usertoken) {
    payload.usertoken = usertoken;
  }


  var signOptions = {
    "algorithm": "RS256"
  };

  var privateKey = REACT_APP_webneurolagejwtPrivate.replace(/\\n/g, '\n');


  var token = jwt.sign(payload, privateKey, signOptions);
  //console.log(token)
  var requestOptions =''
  if(method=='post'){
    requestOptions = {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: `Bearer ` + token,
    },
    body: JSON.stringify(data)
  };

  }else{
     requestOptions = {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
      } 
    };
  }

  const state = store.getState();
  let orgID = state.global.configuration.orgID;
  let userID = state.global.currentUser.userID;
  let cloudUserID = state.global.cloudUser.userID;

  var message = new AxiosMessage(orgID, userID, cloudUserID, data);


  try {



    const response = await fetch(url, requestOptions)
      .then(lresponse => lresponse.json())
      .then(await function (response) {

        message.axiosResponse = response;
        message.data = response;
        message.isSuccess = true;
        // Locationsarr = Locationsarr.concat(ldata.Result)
      })



  } catch (error) {
    message.message = error.message;
    if (error.response) {
      if (error.response.data) {
        message.message = error.response.data.Result;
      }
      message.axiosResponse = error.response;
    } else if (error.request) {
      // The request was made but no response was received
      message.errorRequest = error.request;
    } else {
      // Something happened in setting up the request that triggered an Error
    }
  }

  //TODO: Save all reuests to database
  //console.log('message',message)
  return message;
}


export async function axiosCallNormal(method, url, config, data, usetoken) {
  if (usetoken) {
    let JWT = getJWT();
    if (JWT.token != null) {
      config["Authorization"] = "bearer " + JWT.token;
    }
  }

  const state = store.getState();
  let orgID = state.global.configuration.orgID;
  let userID = state.global.currentUser.userID;
  let cloudUserID = state.global.cloudUser.userID;

  var message = new AxiosMessage(orgID, userID, cloudUserID, data);

  try {
    const response = await axios({
      method: method,
      url: url,
      headers: config,
      data: data,
    });

    message.axiosResponse = response;
    message.data = response.data;
    message.isSuccess = true;
  } catch (error) {
    message.message = error.message;
    if (error.response) {
      if (error.response.data) {
        message.message = error.response.data.Result;
      }
      message.axiosResponse = error.response;
    } else if (error.request) {
      // The request was made but no response was received
      message.errorRequest = error.request;
    } else {
      // Something happened in setting up the request that triggered an Error
    }
  }

  //TODO: Save all reuests to database
  //console.log(message);
  return message;
}
