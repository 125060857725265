import React, { Component } from "react";

class Index extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() { }

    render() {
        return (
            <div>

            </div>
        );
    }
}

export default Index;