import { axiosCall } from "../apiCall";
import { SERVER_METHODS } from "../../config/enums";
import { stringFormat, formatDate } from "../../global/helpers";
import PATHS from "../../../../json/paths.json";
import store from "../../../../redux/store";

const { REACT_APP_webneurolageAPI } = process.env;

export function GetSchema() {
  const state = store.getState();
  const data = {
    pos: state.current.selectedPOS,
    template: state.current.selectedTemplateType,
    orgid: state.global.configuration.orgID,
  };
  var path = REACT_APP_webneurolageAPI + stringFormat(PATHS.GetSchema, data);
  return axiosCall(SERVER_METHODS.GET, path, {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }, {}, false);
}

export function SaveTemplate(htmlData, htmlHeaderData, htmlFooterData, htmlTotalData) {
  const state = store.getState();
  var template = state.template;
  var selectedTemplate = state.current.selectedTemplate;
  return SaveTemplateData(template,htmlData, htmlHeaderData, htmlFooterData, htmlTotalData,selectedTemplate);
}

export function SaveTemplateData(template, htmlData, htmlHeaderData, htmlFooterData, htmlTotalData, selectedTemplate = null) {
  const state = store.getState();
  let date = JSON.parse(JSON.stringify(new Date()));
  let userID = state.global.currentUser.userID;
  let CloudUserID = state.global.cloudUser.userID;

  var data = {};
  if (selectedTemplate === null || !selectedTemplate)
    data = {
      orgID: sessionStorage.getItem('orgID'),
    cloudLocationID: sessionStorage.getItem('LocationID'),
      //: 1,
      Description: "",
      CreatedDate: date,
      CreatedBy: userID,
      IsDefault: false,
      IsDeleted: false,
      CloudCreatedBy: CloudUserID,
      CloudModifiedBy: CloudUserID,
    };
  else data = selectedTemplate;

  var TemplateTypeID = 0
  if (state.current.selectedTemplateType == 'invoicetemplate') {
    TemplateTypeID = 1
  } else if (state.current.selectedTemplateType == 'proformainvoicetemplate') {
    TemplateTypeID = 2
  } else if (state.current.selectedTemplateType == 'deliverynotetemplate') {
    TemplateTypeID = 3
  }

  data = {
    ...data,
    TemplateName: template.prop.templateName,
    TemplateData: JSON.stringify(template),
    ModifiedBy: userID,
    ModifiedDate: date,
    //TODO: Make this dynamic
    //(Invoice - 1, ProformaInvoice - 2, DeliveryNote - 3)
    TemplateTypeID: TemplateTypeID,
    HTMLData: {
      HTMLString: htmlData,
      HTMLHeaderSrting: htmlHeaderData,
      HTMLFooterString: htmlFooterData,
      HTMLTotalString: htmlTotalData
    }
  };
  //-- v1/{pos}/{template}/add
  var path =
    REACT_APP_webneurolageAPI +
    stringFormat(selectedTemplate ? PATHS.UpdateTemplate : PATHS.SaveTemplate, {
      pos: state.current.selectedPOS,
      template: state.current.selectedTemplateType,
    });

  console.log(data);
  return axiosCall(SERVER_METHODS.POST, path, {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }, data, false);
}

export function DeleteTemplate(template) {
  const state = store.getState();
  var selectedTemplate = state.current.selectedTemplate;
  var path =
    REACT_APP_webneurolageAPI +
    stringFormat( PATHS.UpdateTemplate, {
      pos: state.current.selectedPOS,
      template: state.current.selectedTemplateType,
    });

  return axiosCall(SERVER_METHODS.POST, path, {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }, template, false);
}

export function GetAllTemplates() {
  const state = store.getState();

  var data = {
    orgID: sessionStorage.getItem('orgID'),
    cloudLocationID: sessionStorage.getItem('LocationID'),
  };

  //-- v1/{pos}/{template}/fetch

  var path =
    REACT_APP_webneurolageAPI +
    stringFormat(PATHS.FetchTemplates, {
      pos: state.current.selectedPOS,
      template: state.current.selectedTemplateType,
    });


  if (state.current.selectedTemplateType == 'invoicetemplate') {
    path = path + "1"
  } else if (state.current.selectedTemplateType == 'proformainvoicetemplate') {
    path = path + "2"
  } else if (state.current.selectedTemplateType == 'deliverynotetemplate') {
    path = path + "3"
  }

  return axiosCall(SERVER_METHODS.POST, path, {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }, data, false);
}

export async function SetDefault(templates, defaultTemplate) {
  //console.log(templates);
  templates.forEach((t) => {
    console.log(t);
    var data = t.TemplateData;
    // let htmlString=t.HTMLData.HTMLString || ""
    try {
      data = JSON.parse(data);
      SaveTemplateData(data, t.HTMLData.HTMLString, t.HTMLData.HTMLHeaderSrting, t.HTMLData.HTMLFooterString, t.HTMLData.HTMLTotalString , t);
    } catch (error) {
      //data = [];
    }
  });
  return SaveTemplateData(
    JSON.parse(defaultTemplate.TemplateData),
    defaultTemplate.HTMLData.HTMLString || "",
    defaultTemplate.HTMLData.HTMLHeaderSrting || "",
    defaultTemplate.HTMLData.HTMLFooterString || "",
    defaultTemplate.HTMLData.HTMLTotalString || "",
    defaultTemplate
  );
}
