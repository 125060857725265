export const INVOICE_SECTIONS = {
  PROPS: "props",
  HEAD: "head",
  ITEMS: "items",
  TOTAL: "total",
  FOOT: "foot",
};

export const PAPER_SIZE = {
  A4: "a4",
  LETTER: "letter",
};

export const ORIENTATION = {
  PORTRAIT: "portrait",
  LANDSCAPE: "landscape",
};

export const SERVER_METHODS = {
  GET: "get",
  POST: "post",
};

export const RESIZE_DIRECTIONS = {
  TOP_LEFT: "topLeft",
  TOP_RIGHT: "topRight",
  BOTTOM_LEFT: "bottomLeft",
  BOTTOM_RIGHT: "bottomRight",
};
