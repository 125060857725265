import { combineReducers } from "redux";
import { globalReducer } from "./globalReducer";
import { currentReducer } from "./currentReducer";
import { templateReducer } from "./templateReducer";

export const reducers = combineReducers({
  global: globalReducer,
  current: currentReducer,
  template: templateReducer,
});
