import React, { Component } from "react";
import { Container, Row, Col, Aside } from "react-bootstrap";
import { Button } from "react-bootstrap";
import {Redirect } from "react-router-dom";
import Header from "./parts/header";
import Body from "./parts/body";
import Left from "./parts/left";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserCircle,
  faTimes,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(faUserCircle, faTimes, faArrowDown);

class Index extends Component {
  constructor(props) {
    super(props);

  
  
  //  console.log(JSON.parse(sessionStorage.getItem('children')))
    this.customer = {
      name: JSON.parse(sessionStorage.getItem('fullUserDetails')).FirstName +' ' + JSON.parse(sessionStorage.getItem('fullUserDetails')).LastName,
      userName: JSON.parse(sessionStorage.getItem('fullUserDetails')).UserName,
      userID: JSON.parse(sessionStorage.getItem('fullUserDetails')).UserID,
      email: JSON.parse(sessionStorage.getItem('fullUserDetails')).Email,
    };

    this.state = {
      showNotificatios: false,
      showSettings: false,
      showHelp: false,
      showUserInfo: false,
      greetingsMessage: "",
    };
  }


  
  componentDidMount() {

    var welcome;
    var date = new Date();
    var hour = date.getHours();
    var minute = date.getMinutes();
    var second = date.getSeconds();
    if (minute < 10) {
      minute = "0" + minute;
    }
    if (second < 10) {
      second = "0" + second;
    }
    if (hour < 12) {
      welcome = "Good Morning";
    } else if (hour < 17) {
      welcome = "Good Afternoon";
    } else {
      welcome = "Good Evening";
    }
   // console.log(welcome);


    this.setState({ greetingsMessage: welcome });
  }

  resetShowState = function () {
    this.setState({
      showNotificatios: false,
      showSettings: false,
      showHelp: false,
      showUserInfo: false,
    });
  };

  signOut = function () {
    sessionStorage.setItem('loggedin',false)
    window.location.href="/login"
  };

  render() {
    return (
      <Container fluid>
        <div className="row header">
          <div className="col p-0">
            <div className="top-selection">
              <div className="nav-logo-wrapper">
                <div className="nav-logo"></div>
              </div>
            </div>
            <div className="top_menu">
              <Button className="pl-0" variant="none">
                {this.state.greetingsMessage + ", "}
                <strong>{this.customer.name}</strong>
              </Button>

              <div className="ms-auto float-end">
                <p>{sessionStorage.getItem('LocationName')}</p>
                <Button
                  variant="link"
                  className="top_menu-button"
                  onClick={(e) => {
                    this.resetShowState();
                    this.setState({
                      showNotificatios: !this.state.showNotificatios,
                    });
                  }}
                >
                  <FontAwesomeIcon icon="bell" />
                </Button>
                <Button
                  variant="link"
                  className="top_menu-button"
                  onClick={(e) => {
                    this.resetShowState();
                    this.setState({
                      showSettings: !this.state.showSettings,
                    });
                  }}
                >
                  <FontAwesomeIcon icon="cog" />
                </Button>
                <Button
                  variant="link"
                  className="top_menu-button"
                  onClick={(e) => {
                    this.resetShowState();
                    this.setState({
                      showHelp: !this.state.showHelp,
                    });
                  }}
                >
                  <FontAwesomeIcon icon="question-circle" />
                </Button>
                <Button
                  variant="link"
                  className="top_menu-button"
                  onClick={(e) => {
                    this.resetShowState();
                    this.setState({ showUserInfo: !this.state.showUserInfo });
                  }}
                >
                  <FontAwesomeIcon icon="user-circle" />
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="row body">
          <div className="col p-0">
            <Body />

            {/* notifications */}
            <div
              className="flyout notifications"
              style={{
                display: this.state.showNotificatios ? "block" : "none",
              }}
            >
              <div className="section info">
                <FontAwesomeIcon
                  className="info-close"
                  icon="times"
                  size="lg"
                  onClick={(e) => {
                    this.setState({ showNotificatios: false });
                  }}
                />
              </div>
            </div>

            {/* settings */}
            <div
              className="flyout notifications"
              style={{ display: this.state.showSettings ? "block" : "none" }}
            >
              <div className="section info">
                <FontAwesomeIcon
                  className="info-close"
                  icon="times"
                  size="lg"
                  onClick={(e) => {
                    this.setState({ showSettings: false });
                  }}
                />
              </div>
            </div>

            {/* help */}
            <div
              className="flyout notifications"
              style={{ display: this.state.showHelp ? "block" : "none" }}
            >
              <div className="section info">
                <FontAwesomeIcon
                  className="info-close"
                  icon="times"
                  size="lg"
                  onClick={(e) => {
                    this.setState({ showHelp: false });
                  }}
                />
              </div>
            </div>

            {/* user */}
            <div
              className="flyout user"
              style={{ display: this.state.showUserInfo ? "block" : "none" }}
            >
              <div className="section info">
                <FontAwesomeIcon icon="user-circle" size="8x" />
                <h2 className="user-full-name mt-3">{this.customer.name}</h2>
                <p className="user-name m-0">{this.customer.username}</p>
                <p className="user-id m-0">User ID: {this.customer.userID}</p>
                <p>
                  <small className="text-muted">{this.customer.email}</small>
                </p>

                <p>
                  <a className="text-primary" href="" target="_blank">
                    {"My Account"}
                  </a>
                  <span className="text-muted">|</span>
                  <a className="text-danger" href="#" onClick={(e) => {
                    this.signOut();
                  }}>
                    
                    {"Sign Out"}
                  </a>
                </p>

                <FontAwesomeIcon
                  className="info-close"
                  icon="times"
                  size="lg"
                  onClick={(e) => {
                    this.setState({ showUserInfo: false });
                  }}
                />
              </div>

              <div className="section d-flex">
                <span className="text">MY ORGANIZATIONS</span>
                <div className="align-self-center ms-auto">
                  <Button variant="primary">Manage</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default Index;
