import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { Box } from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import { Container, Row, Col } from "react-bootstrap";

import Dashboard from "../content/dashboard/index";
import Template from "../content/templates/index";
import Invoice from "../content/print-document/index";

import Left from "./left";

class Body extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Box className="center-panel">
          <Switch>
            <Route
              path={["/:path/*", "/:path", "/"]}
              render={(props) => (
                <aside>
                  <Left {...props} />
                </aside>
              )}
            ></Route>
          </Switch>
          <>
            <div className="main col">
              <Switch>
                <Route exact path={"/templates/import"} component={Dashboard} />
                <Route
                  exact
                  path={[
                    "/templates",
                    "/templates/:type",
                    "/templates/:type/:id",
                  ]}
                  component={Template}
                />
                <Route
                  exact
                  path={[
                    "/print-documents",
                    "/print-documents/:type",
                    "/print-documents/:type/:id",
                  ]}
                  component={Invoice}
                />

                <Route path="" component={Dashboard} />
              </Switch>
            </div>
          </>
        </Box>
      </>
    );
  }
}

export default Body;
