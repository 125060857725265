import React, { Component } from "react";
import {
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Card,
  ListGroup
} from "react-bootstrap";
import { BorderOutlined } from '@ant-design/icons';
// import {Button as AntdButton, Space} from 'antd'
import sha256 from "sha256";

import { connect } from "react-redux";
import { updateHead } from "../../../../redux/actions/TemplateActions";
import ColorPicker from "../../../../com/lib/plugins/color-picker/ColorPicker";
import {
  createElement,
  createShape,
  createImageElement,
  companyPath,
  changeProprty,
  changeProprtyM,
} from "../../../../com/lib/global/helpers";
import { uploadImage } from "../../../../com/lib/api/data/Image";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { faCircle, faSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(faLightbulb);

class Head extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attributes: props.attributes,
      selectedAttributeSection: "",
      selectedAttributePath: "",

      selectedPrimary: "",
      primaryAttributes: [],

      selectedSecondary: "",
      secondaryAttributes: [],
      tempImage: null
    };
    this._selectAttributeSection = this._selectAttributeSection.bind(this);
    this._onCompanyPropChange = this._onCompanyPropChange.bind(this);

    this.props.callbackFromParents(this.props.properties);
  }

  // #region functions

  componentDidMount() {
    //this.props.callbackFromParents(this.props.properties);
  }

  _selectAttributeSection = (key) => {
    var ele = this.state.attributes[key].Properties;
    this.setState({
      selectedAttributeSection: key,
      selectedAttributePath: "",
      primaryAttributes: ele,
      selectedPrimary: "",
      selectedSecondary: "",
      secondaryAttributes: [],
    });
  };

  _onPrimaryAttributeChange = (e) => {
    var index = e.target.selectedIndex;
    var ele = e.target.childNodes[index];
    var key = ele.getAttribute("data-key");

    var dataValue = JSON.parse(e.target.value);
    this.setState(
      {
        selectedPrimary: key,
        selectedSecondary: "",
        selectedAttributePath: "",
      },
      function () {
        if (typeof dataValue == "object") {
          this.setState({ secondaryAttributes: dataValue });
        } else {
          this.setState({ secondaryAttributes: [] }, function () {
            this.setSelectedAttributePath();
          });
        }
      }
    );
  };

  _onSecondaryAttributeChange = (e) => {
    var index = e.target.selectedIndex;
    var ele = e.target.childNodes[index];
    var key = ele.getAttribute("data-key");

    var dataValue = JSON.parse(e.target.value);
    this.setState(
      {
        selectedSecondary: key,
        selectedAttributePath: "",
      },
      function () {
        this.setSelectedAttributePath();
      }
    );
  };

  setSelectedAttributePath = function () {
    var path =
      this.state.selectedAttributeSection + "." + this.state.selectedPrimary;
    path +=
      this.state.selectedSecondary !== ""
        ? "." + this.state.selectedSecondary
        : "";

    this.setState({
      selectedAttributePath: path,
    });
  };

  _addElementtoCanves = (e) => {
    if (this.state.selectedAttributePath !== "") {
      var element = createElement(this.state.selectedAttributePath);
      var properties = {
        ...this.props.properties,
        elements: {
          ...this.props.properties.elements,
          [element.name.replace(".", "")]: element,
        },
      };

      this.props.updateHead(properties);
    }
  };

  _addShapetoCanves = (e, shapeName) => {
    if (this.state.selectedAttributePath !== "") {
      var element = createShape(this.state.selectedAttributePath);
      var shapes = {
        ...this.props.properties,
        shapes: {
          ...this.props.properties.shapes,
          [element.name.replace(".", "")]: element,
        },
      };

      this.props.updateHead(shapes);
    }
  };

  _handleTextChange = (e, data) => {

    let textLabel = { ...data, text: e.target.value }
    var props = {
      ...this.props.properties,
      elements: {
        ...this.props.properties.elements,
        [data.name]: textLabel
      }
    };
    this.props.updateHead(props);
  }

  _renderElementList = (section) => {
    var index = 0;

    return Object.keys(this.props.properties.elements).map((key) => {
      var data = this.props.properties.elements[key];
      index++;
      var id = sha256(section + "-ele-" + index);

      var isSelected = false;
      return (
        <ListGroup.Item
          className={
            "d-flex justify-content-between lh-condensed" +
            (isSelected ? " bg-primary text-white" : " bg-white")
          }
          data-target={data.id}
          data-section={"head"}
          data-path={"elements"}
          data-index={data.name.replace(".", "")}
          key={key}
          onClick={(e) => this.props.onElementSelected(data.id)}
        >
          <div>
            <h6 className="my-0 tmplate-multiline-text" title={"{" + data.text + "}"}>
              {data.name}
            </h6>
            <small
              className="text-muted"
              style={{
                display: "block",
                width: "240px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              title={"{" + data.name + "}"}
            >
              <input type="text" onChange={(e) => this._handleTextChange(e, data)} value={data.text} />
            </small>
            {data.name === "EposSalesDefinition.InvoiceDate" || data.name === "EposSalesDefinition.InvoiceDueDate" || data.name === "EposSalesDefinition.DeliveryDate" ?
              <div>
                <select placeholder="Date Format" style={{ width: '200px' }} onChange={(e) => this._handleChangeDateFormat(data, e)} defaultValue={data.dateFormat}>
                  <option>Date Format</option>
                  <option>YYYY/MM/DD</option>
                  <option>YYYY-MM-DD</option>
                  <option> YYYY-MM-DD : HH:mm</option>
                  <option>YYYY-MM-DD : HH:mm:ss</option>
                </select>
              </div>
              : null}
          </div>
          <span className="text-muted">
            <Button variant="link" onClick={() => this._handleRemoveElement(data)}>
              <FontAwesomeIcon icon="minus" />
            </Button>
          </span>
        </ListGroup.Item>
      );
    });
  };

  _renderShapesList = (section) => {
    var index = 0;

    return Object.keys(this.props.properties.shapes).filter(s => s!=='border').map((key) => {
      var data = this.props.properties.shapes[key];
      index++;
      var id = sha256(section + "-ele-" + index);

      var isSelected = false;
      return (
        <ListGroup.Item
          className={
            "d-flex justify-content-between lh-condensed" +
            (isSelected ? " bg-primary text-white" : " bg-white")
          }
          data-target={data.id}
          data-section={"head"}
          data-path={"elements"}
          data-index={data.name.replace(".", "")}
          key={key}
          onClick={(e) => this.props.onElementSelected(data.id)}
        >
          <div>
            <h6 className="my-0 tmplate-multiline-text" title={"{" + data.text + "}"}>
              {data.name}
            </h6>
          </div>
          <span className="text-muted">
            <Button variant="link" onClick={() => this._handleRemoveShape(data)}>
              <FontAwesomeIcon icon="minus" />
            </Button>
          </span>
        </ListGroup.Item>
      );
    });
  };


  _handleRemoveElement = (data) => {
    let length = data.name.split('.').length
    let templmntselemnets = { ...this.props.properties.elements }
    if (length < 3) {
      delete templmntselemnets[data.name.split('.').join('')]
    } else {
      delete templmntselemnets[data.name.split('.')[0] + data.name.split('.')[1] + '.' + data.name.split('.')[2]]
    }
    var props = {
      ...this.props.properties,
      elements: templmntselemnets
    };
    this.props.updateHead(props);
  }

  _handleRemoveShape = (data) => {
    let templmntselemnets = { ...this.props.properties.shapes }

      delete templmntselemnets[data.name]

    var props = {
      ...this.props.properties,
      shapes: templmntselemnets
    };
    this.props.updateHead(props);
  }

  _handleChangeDateFormat = (data, e) => {
    let templmntselemnets = { ...this.props.properties.elements }
    let uptadedVal = Object.assign(
      this.props.properties.elements[data.name.split('.').join('')],
      { dateFormat: e.target.value }
    )
    debugger;
    var props = {
      ...this.props.properties,
      elements: Object.assign(templmntselemnets, { [data.name.split('.').join('')]: uptadedVal })
    };
    this.props.updateHead(props);
  }

  _onImageSelect = async (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];


      if (
        img.type !== "image/png" &&
        img.type !== "image/jpeg" &&
        img.type !== "image/jpg"
      ) {
        alert(`${img.name} is not an image file`);
        return false;
      }

      this.setState({
        tempImage: URL.createObjectURL(img)
      })

      uploadImage(img).then((result) => {
        console.log("HEAD result===>>", result)
        if (result.data && result.status === 200) {
          var path = companyPath();
          var element = createImageElement(path + ".CompanyLogo");
          element.logo = URL.createObjectURL(img);
          element.showLogo = this.props.properties.logo.showLogo;

          var props = {
            ...this.props.properties,
            // logo: "http://localhost:4000/"+result.data.imageName+'?v=1.0',
            logo: result.data.Result,
          };
          this.props.updateTempLogo(URL.createObjectURL(img));
          this.props.updateHeaderLogo(props);

        }
      })



    }
  };

  _handleImageRemove = () => {
    if (this.props.properties.logo.logo !== null) {
      this.props.handleImageRemove()
    }
    this.setState({
      tempImage: null
    })
  }

  _onCompanyPropChange = (e, key) => {
    // debugger
    var chk = e.target.checked;
    var props = {
      ...this.props.properties,
      companyProps: {
        ...this.props.properties.companyProps,
        [key]: {
          ...this.props.properties.companyProps[key],
          show: chk,
        },
      },
    };
    this.props.updateHead(props);
  };
  // #endregion

  render() {
    return (
      <>
        <div className="column-header">
          <span className="font-large">Header Properties</span>
          <Button
            variant="link ml-2"
            className="text-dark text-decoration-none"
            onClick={(e) => {
              this.props.showToolTips(true);
            }}
          >
            <FontAwesomeIcon icon="lightbulb" className="me-2" />
            Tool Tips
          </Button>
        </div>
        <div className="column-body">
          {typeof this.props.properties !== "undefined" ? (
            <Form>
              <Row>
                <Col sm={12}>
                  <Form.Group controlId="forPrintHeaderEveryPage">
                    <Form.Check
                      type="checkbox"
                      label="Print Header Every Page"
                      className="fw-bold"
                      checked={
                        this.props.properties &&
                        this.props.properties.printingHeaderEveryPage
                      }
                      onChange={(e) =>
                        changeProprty(
                          "printingHeaderEveryPage",
                          e.target.checked,
                          this.props,
                          this.props.updateHead
                        )
                      }
                    />
                  </Form.Group>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Group controlId="forOrganizationLogo">
                    <Form.Check
                      type="checkbox"
                      label="Show Organization Logo"
                      className="fw-bold"
                      checked={
                        this.props.properties?.logo?.showLogo || false
                      }
                      onChange={(e) =>
                        changeProprtyM(
                          "logo.showLogo",
                          e.target.checked,
                          this.props,
                          this.props.updateHead
                        )
                      }
                    />
                  </Form.Group>
                </Col>
                {this.props.properties?.logo?.showLogo ? (
                  <Col sm={12}>
                    <Card className="mb-2">
                      <div
                        className="border mb-2 p-3"
                        style={{ textAlign: "center" }}
                      >

                        {this.state.tempImage !== null ?

                          <>
                            <img
                              // variant="center"
                              src={this.state.tempImage}
                              style={{ height: "150px" }}
                            />
                            <div className="pt-4">
                              <Button variant="outline-danger" onClick={this._handleImageRemove}>Remove</Button>
                            </div>
                          </>
                          : <>
                            {this.props.properties.logo.logo == null ? (
                              <Form.Group controlId="forLogo" className="mb-3">
                                <Form.Label>Upload File</Form.Label>
                                <Form.Control
                                  type="file"
                                  className="form-control"
                                  onChange={(e) => this._onImageSelect(e)}
                                />
                              </Form.Group>
                            ) : (
                              <>
                                {this.state.tempImage !== null ?
                                  <img
                                    src={this.state.tempImage}
                                    style={{ height: "150px" }}
                                  />
                                  : <Card.Img
                                    variant="center"
                                    src={this.props.properties.logo.logo}
                                    style={{ height: "150px" }}
                                  />
                                }
                                <div className="pt-4">
                                  <Button variant="outline-danger" onClick={this._handleImageRemove}>Remove</Button>
                                </div>
                              </>
                            )}
                          </>

                        }
                      </div>
                      <Card.Body>
                        <Card.Text>
                          You can change the logo in Organization Profile.
                        </Card.Text>
                        <Form.Label className="fw-bold">Resize Logo</Form.Label>
                        <Form.Control
                          type="range"
                          className="w-100"
                          min="0"
                          max="100"
                          step="5"
                          value={this.props.properties.logo.logoSize}
                          onChange={(e) =>
                            changeProprtyM(
                              "logo.logoSize",
                              e.target.value,
                              this.props,
                              this.props.updateHead
                            )
                          }
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
              {Object.keys(this.state.attributes[companyPath()].Properties).map(
                (key) => {
                  if (key.includes("Logo")) return false;
                  return (
                    <Row key={key}>
                      <Col>
                        <Form.Group controlId={"forCompany" + key}>
                          <Form.Check
                            key={key}
                            type="checkbox"
                            checked={
                              this.props.properties &&
                              Object.keys(this.props.properties.companyProps)
                                .length > 0 &&
                              this.props.properties.companyProps[key]?.show
                            }
                            label={
                              "Show " +
                              key
                                .replace(/([A-Z])/g, " $1")
                                .trim()
                                .replace("V A T", "VAT")
                            }
                            onChange={(e) => this._onCompanyPropChange(e, key)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  );
                }
              )}

              <Row>
                <Col>
                  <Form.Group controlId="forShowDocumentTitle">
                    <Form.Check
                      type="checkbox"
                      label="Show Document Title"
                      checked={
                        this.props.properties &&
                        this.props.properties.documentTitle.show
                      }
                      onChange={(e) =>
                        changeProprtyM(
                          "documentTitle.show",
                          e.target.checked,
                          this.props,
                          this.props.updateHead
                        )
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <div className="fw-bold mt-3">
                    Draw Shape
                  </div>
                  <div>
                    <Button variant="light border"
                      onClick={(e) => {
                        // this._addShapetoCanves(e, 'box')
                        this.setState(
                          {
                            selectedAttributePath:
                              "box-" +
                              parseInt(
                                Object.keys(this.props.properties.shapes)
                                  .length + 1
                              ),
                          },
                          function () {
                            this._addShapetoCanves(e, 'box')
                          }
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faSquare} size="1x" />
                    </Button>

                    &nbsp;&nbsp;
                    <Button variant="light border"
                      onClick={(e) => {
                        // this._addShapetoCanves(e, 'circle');
                        this.setState(
                          {
                            selectedAttributePath:
                              "circle-" +
                              parseInt(
                                Object.keys(this.props.properties.shapes)
                                  .length + 1
                              ),
                          },
                          function () {
                            this._addShapetoCanves(e, 'circle');
                          }
                        );
                      }}>
                      <FontAwesomeIcon icon={faCircle} size="1x" />
                    </Button>

                  </div>
                </Col>
              </Row>
              <br />

              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label for="titleColor">Border Colour</Form.Label>
                    <InputGroup>
                      <FormControl
                       value={this.props.properties?.shapes?.border?.color || '#333333FF'}
                      />
                      <InputGroup.Append>
                        <ColorPicker
                          {...this.props}
                          id="titleColor"
                          name="titleColor"
                          color={
                            this.props.properties?.shapes?.border?.color || '#333333FF'
                          }
                          callbackFromParents={(e) => {
                            changeProprtyM(
                              "shapes.border.color",
                              e.hex,
                              this.props,
                              this.props.updateHead
                            );
                          }}
                        />
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group controlId="forTitleFontSize">
                    <Form.Label>Border</Form.Label>
                    <InputGroup>
                      <FormControl
                        value={
                          this.props.properties?.shapes?.border?.size || 1
                        }
                        onChange={(e) => {
                          changeProprtyM(
                            "shapes.border.size",
                            Number(e.target.value),
                            this.props,
                            this.props.updateHead
                          )
                        }
                        }
                        type="number"
                      />
                      <InputGroup.Text>px</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label for="titleColor">Fill Colour</Form.Label>
                    <InputGroup>
                      <FormControl
                       value={this.props.properties?.shapes?.border?.fillColor || '#FFFFFFFF'}
                      />
                      <InputGroup.Append>
                        <ColorPicker
                          {...this.props}
                          id="titleColor"
                          name="titleColor"
                          color={
                            this.props.properties?.shapes?.border?.fillColor || '#FFFFFFFF'
                          }
                          callbackFromParents={(e) => {
                            changeProprtyM(
                              "shapes.border.fillColor",
                              e.hex,
                              this.props,
                              this.props.updateHead
                            );
                          }}
                        />
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <hr />

              <Row>
                <Col>
                  <Form.Group controlId="forShowDocumentTitle">
                    <Form.Label className="fw-bold mt-3">
                      Document Title
                    </Form.Label>
                    <Form.Control
                      value={this.props.properties.documentTitle.title}
                      onChange={(e) =>
                        changeProprtyM(
                          "documentTitle.title",
                          e.target.value,
                          this.props,
                          this.props.updateHead
                        )
                      }
                      type="text"
                      readOnly={
                        !this.props.properties.documentTitle.show ? true : false
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label for="titleColor">Title Colour</Form.Label>
                    <InputGroup>
                      <FormControl
                        value={this.props.properties.documentTitle.style.color}
                      />
                      <InputGroup.Append>
                        <ColorPicker
                          {...this.props}
                          id="titleColor"
                          name="titleColor"
                          color={
                            this.props.properties.documentTitle.style.color
                          }
                          callbackFromParents={(e) => {
                            changeProprtyM(
                              "documentTitle.style.color",
                              e.hex,
                              this.props,
                              this.props.updateHead
                            );
                          }}
                        />
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Col>


                <Col sm={6}>
                  <Form.Group controlId="forTitleFontSize">
                    <Form.Label>Title Font Size</Form.Label>
                    <InputGroup>
                      <FormControl
                        value={
                          this.props.properties.documentTitle.style.fontSize
                        }
                        onChange={(e) => {
                          changeProprtyM(
                            "documentTitle.style.fontSize",
                            Number(e.target.value),
                            this.props,
                            this.props.updateHead
                          )
                        }
                        }
                        type="number"
                      />
                      <InputGroup.Text>pt</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Font </Form.Label>
                    <select
                      className="form-select form-control"
                      aria-label="Default select"
                      onChange={(e) =>
                        changeProprtyM(
                          "font.fontFamily",
                          e.target.value,
                          this.props,
                          this.props.updateHead
                        )
                      }
                      value={
                        this.props.properties?.font?.fontFamily || 'Arial'
                      }
                    >
                      <option value="Arial">Arial</option>
                      <option value="Times New Roman">Times New Roman</option>
                      <option value="Verdana">Verdana</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Open Sans">Open Sans</option>
                      <option value="Roboto">Roboto</option>
                      <option value="Calibri">Calibri</option>
                    </select>
                  </Form.Group>
                </Col>

                <Col sm={6}>
                  <Form.Group controlId="forFontSize">
                    <Form.Label>Font Size</Form.Label>
                    <InputGroup>
                      <FormControl
                        value={
                          this.props.properties?.font?.fontSize || 10
                        }
                        onChange={(e) => {
                          changeProprtyM(
                            "font.fontSize",
                            Number(e.target.value),
                            this.props,
                            this.props.updateHead
                          )
                        }
                        }
                        type="number"
                      />
                      <InputGroup.Text>pt</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Colour</Form.Label>
                    <InputGroup>
                      <FormControl
                        value={this.props.properties?.font?.color || '#000000FF'}
                      />
                      <InputGroup.Append>
                        <ColorPicker
                          {...this.props}
                          color={this.props.properties?.font?.color || '#000000FF'}
                          callbackFromParents={(e) => {
                            changeProprtyM(
                              "font.color",
                              e.hex,
                              this.props,
                              this.props.updateHead
                            );
                          }}
                        />
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Col>

              </Row>
              < hr />
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Background Colour</Form.Label>
                    <InputGroup>
                      <FormControl
                        value={this.props.properties.background.color}
                        readOnly
                      />
                      <InputGroup.Append>
                        <ColorPicker
                          {...this.props}
                          color={this.props.properties.background.color}
                          callbackFromParents={(e) => {
                            changeProprtyM(
                              "background.color",
                              e.hex,
                              this.props,
                              this.props.updateHead
                            );
                          }}
                        />
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group controlId="forBackgroundColorHead">
                    <Form.Check
                      type="checkbox"
                      label="Show Background Color"
                      checked={
                        this.props.properties &&
                        this.props.properties.background.showColor
                      }
                      onChange={(e) =>
                        changeProprtyM(
                          "background.showColor",
                          e.target.checked,
                          this.props,
                          this.props.updateHead
                        )
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label className="fw-bold mt-3">
                      Add new Element
                    </Form.Label>
                    <InputGroup
                      style={{
                        height: "40px",
                      }}
                    >
                      <Button
                        variant="light border"
                        title="Add new text"
                        onClick={(e) => {
                          this.setState(
                            {
                              selectedAttributePath:
                                "Text-Label-" +
                                parseInt(
                                  Object.keys(this.props.properties.elements)
                                    .length + 1
                                ),
                            },
                            function () {
                              this._addElementtoCanves(e);
                            }
                          );
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <i
                          className="bi bi-fonts"
                          style={{ fontSize: "1.3rem" }}
                        ></i>
                      </Button>

                      {/*} TODO: Finish Functionality
                      <Button
                        variant="light border"
                        title="Add new circle"
                        onClick={(e) => {
                          this.setState(
                            { selectedAttributePath: "Square Element" },
                            function () {
                              this._addElementtoCanves(e);
                            }
                          );
                        }}
                      >
                        <i className="bi bi-circle"></i>
                      </Button>

                      <Button
                        variant="light border"
                        title="Add new square"
                        onClick={(e) => {
                          this.setState(
                            { selectedAttributePath: "Circle Element" },
                            function () {
                              this._addElementtoCanves(e);
                            }
                          );
                        }}
                      >
                        <i className="bi bi-square"></i>
                      </Button>
                      */}
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label className="fw-bold mt-3">
                      Additional Properties
                    </Form.Label>
                    <ListGroup className="mb-3">
                      {Object.keys(this.state.attributes).map((key) => {
                        if (
                          this.state.attributes[key].DataType !== "List" && key !== companyPath() && key !== 'EPOSCompanyBankDefinition' && key !== 'EposTemplateSettingsDefinition'
                        ) {
                          return (
                            <ListGroup.Item
                              key={key}
                              className={
                                "d-flex justify-content-between lh-condensed " +
                                (this.state.selectedAttributeSection === key
                                  ? "bg-primary text-white"
                                  : "bg-white")
                              }
                              onClick={(e) => this._selectAttributeSection(key)}
                              style={{ cursor: "pointer" }}
                            >
                              <div>
                                <h6 className="my-0 tmplate-multiline-text">{key}</h6>
                                <small
                                  className={
                                    "small " +
                                    (this.state.selectedAttributeSection === key
                                      ? "bg-primary text-white"
                                      : "bg-white")
                                  }
                                >
                                  {Object.keys(
                                    this.state.attributes[key].Properties
                                  ).length + " inherited properties"}
                                </small>
                              </div>
                            </ListGroup.Item>
                          );
                        }
                      })}
                    </ListGroup>
                  </Form.Group>

                  {Object.keys(this.state.primaryAttributes).length > 0 ? (
                    <Form.Group>
                      <Form.Label className="fw-bold mt-3">
                        Custom Attribute
                      </Form.Label>
                      <InputGroup>
                        <select
                          className="form-select form-control"
                          aria-label="Default select"
                          onChange={(e) => {
                            this._onPrimaryAttributeChange(e);
                          }}
                        >
                          <option>Select attribute</option>
                          {Object.keys(this.state.primaryAttributes).map(
                            (key) => {
                              var value = this.state.primaryAttributes[key];
                              return (
                                <option
                                  key={key}
                                  value={JSON.stringify(value)}
                                  data-key={key}
                                >
                                  {key}
                                </option>
                              );
                            }
                          )}
                        </select>
                        {Object.keys(this.state.secondaryAttributes).length ==
                          0 ? (
                          <InputGroup.Text>
                            <Button
                              variant="link"
                              onClick={(e) => {
                                this._addElementtoCanves(e);
                              }}
                            >
                              <FontAwesomeIcon icon="plus" />
                            </Button>
                          </InputGroup.Text>
                        ) : (
                          <></>
                        )}
                      </InputGroup>
                    </Form.Group>
                  ) : (
                    <></>
                  )}

                  {Object.keys(this.state.secondaryAttributes).length > 0 ? (
                    <Form.Group>
                      <Form.Label className="fw-bold mt-3">
                        Inherited Attribute
                      </Form.Label>
                      <InputGroup>
                        <select
                          className="form-select form-control"
                          aria-label="Default select"
                          onChange={(e) => {
                            this._onSecondaryAttributeChange(e);
                          }}
                        >
                          <option>Select attribute</option>
                          {Object.keys(this.state.secondaryAttributes).map(
                            (key) => {
                              var value = this.state.secondaryAttributes[key];
                              return (
                                <option
                                  key={key}
                                  value={JSON.stringify(value)}
                                  data-key={key}
                                >
                                  {key}
                                </option>
                              );
                            }
                          )}
                        </select>
                        <InputGroup.Text>
                          <Button
                            variant="link"
                            onClick={(e) => {
                              this._addElementtoCanves(e);
                            }}
                          >
                            <FontAwesomeIcon icon="plus" />
                          </Button>
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  ) : (
                    <></>
                  )}

                  <ListGroup
                    className="mb-3 border-1"
                    style={{
                      maxHeight: "300px",
                      overflowX: "none",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    {this._renderElementList()}
                  </ListGroup>
                </Col>
              </Row>
              
              <hr />

              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label className="fw-bold mt-3">
                      Shapes
                    </Form.Label>
                  </Form.Group>
                
                  <ListGroup
                    className="mb-3 border-1"
                    style={{
                      maxHeight: "300px",
                      overflowX: "none",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    {this._renderShapesList()}
                  </ListGroup>
                </Col>
              </Row>
            </Form>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    properties: state.template.head,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateHead: (e) => dispatch(updateHead(e)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Head);
