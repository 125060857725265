import React, { Component } from "react";
import {
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
} from "react-bootstrap";
import { PAPER_SIZE, ORIENTATION } from "../../../../com/lib/config/enums";
import ColorPicker from "../../../../com/lib/plugins/color-picker/ColorPicker";
import {
  changeProprty,
  changeProprtyM,
} from "../../../../com/lib/global/helpers";

import { connect } from "react-redux";
import { updateProp } from "../../../../redux/actions/TemplateActions";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(faLightbulb);

class Properties extends Component {
  constructor(props) {
    super(props);
    //this.props.callbackFromParents(this.props.properties);
  }

  render() {
    return (
      <>
        <div className="column-header">
          <span className="font-large">Template Properties</span>
          <Button
            variant="link ml-2"
            className="text-dark text-decoration-none"
            onClick={(e) => {
              this.props.showToolTips(true);
            }}
          >
            <FontAwesomeIcon icon="lightbulb" className="me-2" />
            Tool Tips
          </Button>
        </div>
        <div className="column-body">
          {typeof this.props.properties !== "undefined" ? (
            <Form>
              <Row>
                <Col>
                  <Form.Group controlId="formTemplateName">
                    <Form.Label className="fw-bold">Template Name</Form.Label>
                    <Form.Control
                      value={this.props.properties.templateName}
                      onChange={(e) =>
                        changeProprty(
                          "templateName",
                          e.target.value,
                          this.props,
                          this.props.updateProp
                        )
                      }
                      required
                      type="text"
                    />
                    <Form.Control.Feedback type="invalid">
                      Template name is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Paper Size</Form.Label>
                    <Form.Check
                      checked={
                        this.props.properties.paperSize === PAPER_SIZE.A4
                          ? true
                          : false
                      }
                      onChange={(e) =>
                        changeProprty(
                          "paperSize",
                          PAPER_SIZE.A4,
                          this.props,
                          this.props.updateProp
                        )
                      }
                      inline
                      label="A4"
                      name="paperSizeA4"
                      type="radio"
                    />
                    <Form.Check
                      checked={
                        this.props.properties.paperSize === PAPER_SIZE.LETTER
                          ? true
                          : false
                      }
                      onChange={(e) =>
                        changeProprty(
                          "paperSize",
                          PAPER_SIZE.LETTER,
                          this.props,
                          this.props.updateProp
                        )
                      }
                      inline
                      label="Letter"
                      name="paperSizeLetter"
                      type="radio"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Orientation</Form.Label>
                    <Form.Check
                      checked={
                        this.props.properties.orientation ===
                        ORIENTATION.PORTRAIT
                          ? true
                          : false
                      }
                      onChange={(e) =>
                        changeProprty(
                          "orientation",
                          ORIENTATION.PORTRAIT,
                          this.props,
                          this.props.updateProp
                        )
                      }
                      inline
                      label="Portrait"
                      name="paperOrientationPotrait"
                      type="radio"
                    />
                    <Form.Check
                      checked={
                        this.props.properties.orientation ===
                        ORIENTATION.LANDSCAPE
                          ? true
                          : false
                      }
                      onChange={(e) =>
                        changeProprty(
                          "orientation",
                          ORIENTATION.LANDSCAPE,
                          this.props,
                          this.props.updateProp
                        )
                      }
                      inline
                      label="Landscape"
                      name="paperOrientationLandscape"
                      type="radio"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formMargins">
                    <Form.Label>Margins (in inches)</Form.Label>
                    <Row className="mt-3">
                      <Col>
                        <Form.Control
                          value={this.props.properties.margin ? this.props.properties.margin.top : 0}
                          onChange={(e) =>
                            changeProprtyM(
                              "margin.top",
                              e.target.value,
                              this.props,
                              this.props.updateProp
                            )
                          }
                          type="number"
                          step="0.1"
                        />
                        <Form.Text className="text-muted">Top</Form.Text>
                      </Col>
                      <Col>
                        <Form.Control
                          value={this.props.properties.margin ? this.props.properties.margin.bottom : 0}
                          onChange={(e) =>
                            changeProprtyM(
                              "margin.bottom",
                              e.target.value,
                              this.props,
                              this.props.updateProp
                            )
                          }
                          type="number"
                          step="0.1"
                        />
                        <Form.Text className="text-muted">Bottom</Form.Text>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <Form.Control
                          value={this.props.properties.margin ? this.props.properties.margin.left : 0}
                          onChange={(e) =>
                            changeProprtyM(
                              "margin.left",
                              e.target.value,
                              this.props,
                              this.props.updateProp
                            )
                          }
                          type="number"
                          step="0.1"
                        />
                        <Form.Text className="text-muted">Left</Form.Text>
                      </Col>
                      <Col>
                        <Form.Control
                          value={this.props.properties.margin ? this.props.properties.margin.right : 0}
                          onChange={(e) =>
                            changeProprtyM(
                              "margin.right",
                              e.target.value,
                              this.props,
                              this.props.updateProp
                            )
                          }
                          type="number"
                          step="0.1"
                        />
                        <Form.Text className="text-muted">Right</Form.Text>
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <hr />
                  <Form.Group controlId="forBackgroundImage" className="mb-3">
                    <Form.Label>Background Image</Form.Label>
                    <Form.Control type="file" className="form-control" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Group controlId="forBackgroundColorProp">
                    <Form.Check
                      type="checkbox"
                      label="Show Background Color"
                      checked={
                        this.props.properties &&
                        this.props.properties.background.showColor
                      }
                      onChange={(e) =>
                        changeProprtyM(
                          "background.showColor",
                          e.target.checked,
                          this.props,
                          this.props.updateProp
                        )
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Background Colour</Form.Label>
                    <InputGroup style={{ position: "inherit" }}>
                      <FormControl
                        value={this.props.properties.background.color}
                        readOnly
                      />
                      <InputGroup.Append>
                        <ColorPicker
                          {...this.props}
                          color={this.props.properties.background.color}
                          callbackFromParents={(e) => {
                            changeProprtyM(
                              "background.color",
                              e.hex,
                              this.props,
                              this.props.updateProp
                            );
                          }}
                        />
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group controlId="formLabelColour">
                    <Form.Label>Label Colour</Form.Label>
                    <InputGroup style={{ position: "inherit" }}>
                      <FormControl
                        value={this.props.properties.labelColor}
                        readOnly
                      />
                      <InputGroup.Append>
                        <ColorPicker
                          {...this.props}
                          color={this.props.properties.labelColor}
                          callbackFromParents={(e) => {
                            changeProprty(
                              "labelColor",
                              e.hex,
                              this.props,
                              this.props.updateProp
                            );
                          }}
                        />
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="formFontColour">
                    <Form.Label>Font Colour</Form.Label>
                    <InputGroup style={{ position: "inherit" }}>
                      <FormControl
                        value={this.props.properties.fontColor}
                        readOnly
                      />
                      <InputGroup.Text className="p-0">
                        <ColorPicker
                          {...this.props}
                          color={this.props.properties.fontColor}
                          callbackFromParents={(e) => {
                            changeProprty(
                              "fontColor",
                              e.hex,
                              this.props,
                              this.props.updateProp
                            );
                          }}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group controlId="formFontSize">
                    <Form.Label>Font Size</Form.Label>
                    <InputGroup>
                      <FormControl
                        value={this.props.properties.fontSize}
                        onChange={(e) =>
                          changeProprty(
                            "fontSize",
                            e.target.value,
                            this.props,
                            this.props.updateProp
                          )
                        }
                        type="number"
                      />
                      <InputGroup.Text>pt</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <hr />
                  <Form.Group controlId="formMargins">
                    <Form.Label className="fw-bold mt-3">
                      Price Notification
                    </Form.Label>
                    <Row className="mt-3">
                      <Col sm={12}>
                        <Form.Group controlId="forApplyToFirstPageOnly">
                          <Form.Check
                            type="checkbox"
                            label="Send Price Change Notifications"
                            className="fw-bold"
                            value={
                              this.props.properties.isPriceChangeNotification
                            }
                            onChange={(e) =>
                              changeProprty(
                                "isPriceChangeNotification",
                                e.target.checked,
                                this.props,
                                this.props.updateProp
                              )
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group controlId="forWatermarkBackground">
                          <Form.Label>Background Colour</Form.Label>
                          <InputGroup style={{ position: "inherit" }}>
                            <FormControl
                              value={
                                this.props.properties.priceNotificationColour ? this.props.properties.priceNotificationColour
                                  .backgroundColour :''
                              }
                              readOnly
                            />
                            <InputGroup.Text className="p-0">
                              <ColorPicker
                                {...this.props}
                                color={
                                  this.props.properties.priceNotificationColour ? this.props.properties.priceNotificationColour
                                  .backgroundColour :''
                                }
                                callbackFromParents={(e) => {
                                  changeProprtyM(
                                    "priceNotificationColour.backgroundColour",
                                    e.hex,
                                    this.props,
                                    this.props.updateProp
                                  );
                                }}
                              />
                            </InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group controlId="formWatermarkForeground">
                          <Form.Label>Font Colour</Form.Label>
                          <InputGroup style={{ position: "inherit" }}>
                            <FormControl
                              value={
                                this.props.properties.priceNotificationColour? 
                                this.props.properties.priceNotificationColour
                                  .foreColour : ''
                              }
                              readOnly
                            />
                            <InputGroup.Text className="p-0">
                              <ColorPicker
                                {...this.props}
                                color={
                                  this.props.properties.priceNotificationColour? 
                                  this.props.properties.priceNotificationColour
                                    .foreColour : ''
                                }
                                callbackFromParents={(e) => {
                                  changeProprtyM(
                                    "priceNotificationColour.foreColour",
                                    e.hex,
                                    this.props,
                                    this.props.updateProp
                                  );
                                }}
                              />
                            </InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    properties: state.template.prop,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProp: (e) => dispatch(updateProp(e)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Properties);
