import React, { Component } from "react";
import { div } from "@material-ui/core/";
import { Button } from "react-bootstrap";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBell,
  faCog,
  faQuestionCircle,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(faBell, faCog, faQuestionCircle, faUserCircle);

class Header extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="row header">
        <div className="col p-0">
          <div className="top-slection">
            <a href="#"></a>
          </div>
          <div className="top_menu d-flex">
            <Button className="pl-0" variant="none">
              Good morning, <strong>Administrator</strong>
            </Button>

            <div className="ms-auto">
              <Button variant="link" className="top_menu-button">
                <FontAwesomeIcon icon="bell" />
              </Button>
              <Button variant="link" className="top_menu-button">
                <FontAwesomeIcon icon="cog" />
              </Button>
              <Button variant="link" className="top_menu-button">
                <FontAwesomeIcon icon="question-circle" />
              </Button>
              <Button variant="link" className="top_menu-button">
                <FontAwesomeIcon icon="user-circle" size="lg" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
