import React, { Component, useState } from "react";
import Config from "../../../json/config.json";

import sha256 from "sha256";

export function getJWT() {}

export function stringFormat(str, data) {
  Object.keys(data).forEach(function (key) {
    var regEx = new RegExp("\\{" + key + "\\}", "gm");
    str = str.replace(regEx, data[key]);
  });

  return str;
}

export function encrypt(data) {
  var CryptoJS = require("crypto-js");
  var key = Config.CryptKey;
  var cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
  return cipherText;
}

export function decrypt(data) {
  var CryptoJS = require("crypto-js");
  var key = Config.CryptKey;
  var bytes = CryptoJS.AES.decrypt(data, key);
  var decText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decText;
}

export function createElement(name) {
  var names = name.toString().split(".");
  var id = sha256(name + "-ele-" + Date.now());

  return {
    name: name,
    text: "{" + names[names.length - 1] + "}",
    data: names[names.length - 1],
    id: id,
    style: {
      position: "absolute",
      top: "40px",
      left: "40px",
      zIndex: 1
      /*
      fontFamily: "Helvetica",
      color: "#333333FF",
      fontSize: "12pt",*/
    },
  };
}

export function createShape(name) {
  var names = name.toString().split(".");
  var id = sha256(name + "-ele-" + Date.now());

  return {
    name: name,
    text: "{" + names[names.length - 1] + "}",
    data: names[names.length - 1],
    id: id,
    style: {
      position: "absolute",
      top: "40px",
      left: "40px",
      borderRadius: name.includes('circle')? '50%': 0,
      zIndex: 0
      /*
      fontFamily: "Helvetica",
      color: "#333333FF",
      fontSize: "12pt",*/
    },
  };
}

export function createImageElement(name) {
  var id = sha256(name + "-img-" + Date.now());
  Date.now();
  return {
    name: name,
    showLogo: false,
    logo: "",
    logoSize: 100,
    id: id,
    style: {
      position: "absolute",
      top: "40px",
      left: "40px",
      height: "60px",
      width: "60px",
    },
  };
}

export function createTableElement(name) {
  var names = name.toString().split(".");
  var id = sha256(name + "-table-" + Date.now());

  return {
    name: name,
    text: "{" + names[names.length - 1] + "}",
    data: names[names.length - 1],
    id: id,
    stringFormat: "",
    width: 10,
    header: names[names.length - 1],
    style: {
      backgroundColor: "transparent",
      /*fontFamily: "Helvetica", color: "#333333FF", fontSize: "12pt"*/
    },
  };
}

export function changeProprty(name, value, props, updateFunc) {
  let properties = {
    ...props.properties,
    [name]: value,
  };
  updateFunc(properties);
}

export function changeProprtyM(name, value, props, updateFunc) {
  var names = name.split(".");
  //console.log(names);
  let properties = {
    ...props.properties,
    [names[0]]: {
      ...props.properties[names[0]],
      [names[1]]: names[2]
        ? {
            ...props.properties[names[0]][names[1]],
            [names[2]]: value,
          }
        : value,
    },
  };
  updateFunc(properties);
}

export function formatDate(date, format, utc) {
  var MMMM = [
    "\x00",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var MMM = [
    "\x01",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var dddd = [
    "\x02",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  var ddd = ["\x03", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  function ii(i, len) {
    var s = i + "";
    len = len || 2;
    while (s.length < len) s = "0" + s;
    return s;
  }

  var y = utc ? date.getUTCFullYear() : date.getFullYear();
  format = format.replace(/(^|[^\\])yyyy+/g, "$1" + y);
  format = format.replace(/(^|[^\\])yy/g, "$1" + y.toString().substr(2, 2));
  format = format.replace(/(^|[^\\])y/g, "$1" + y);

  var M = (utc ? date.getUTCMonth() : date.getMonth()) + 1;
  format = format.replace(/(^|[^\\])MMMM+/g, "$1" + MMMM[0]);
  format = format.replace(/(^|[^\\])MMM/g, "$1" + MMM[0]);
  format = format.replace(/(^|[^\\])MM/g, "$1" + ii(M));
  format = format.replace(/(^|[^\\])M/g, "$1" + M);

  var d = utc ? date.getUTCDate() : date.getDate();
  format = format.replace(/(^|[^\\])dddd+/g, "$1" + dddd[0]);
  format = format.replace(/(^|[^\\])ddd/g, "$1" + ddd[0]);
  format = format.replace(/(^|[^\\])dd/g, "$1" + ii(d));
  format = format.replace(/(^|[^\\])d/g, "$1" + d);

  var H = utc ? date.getUTCHours() : date.getHours();
  format = format.replace(/(^|[^\\])HH+/g, "$1" + ii(H));
  format = format.replace(/(^|[^\\])H/g, "$1" + H);

  var h = H > 12 ? H - 12 : H == 0 ? 12 : H;
  format = format.replace(/(^|[^\\])hh+/g, "$1" + ii(h));
  format = format.replace(/(^|[^\\])h/g, "$1" + h);

  var m = utc ? date.getUTCMinutes() : date.getMinutes();
  format = format.replace(/(^|[^\\])mm+/g, "$1" + ii(m));
  format = format.replace(/(^|[^\\])m/g, "$1" + m);

  var s = utc ? date.getUTCSeconds() : date.getSeconds();
  format = format.replace(/(^|[^\\])ss+/g, "$1" + ii(s));
  format = format.replace(/(^|[^\\])s/g, "$1" + s);

  var f = utc ? date.getUTCMilliseconds() : date.getMilliseconds();
  format = format.replace(/(^|[^\\])fff+/g, "$1" + ii(f, 3));
  f = Math.round(f / 10);
  format = format.replace(/(^|[^\\])ff/g, "$1" + ii(f));
  f = Math.round(f / 10);
  format = format.replace(/(^|[^\\])f/g, "$1" + f);

  var T = H < 12 ? "AM" : "PM";
  format = format.replace(/(^|[^\\])TT+/g, "$1" + T);
  format = format.replace(/(^|[^\\])T/g, "$1" + T.charAt(0));

  var t = T.toLowerCase();
  format = format.replace(/(^|[^\\])tt+/g, "$1" + t);
  format = format.replace(/(^|[^\\])t/g, "$1" + t.charAt(0));

  var tz = -date.getTimezoneOffset();
  var K = utc || !tz ? "Z" : tz > 0 ? "+" : "-";
  if (!utc) {
    tz = Math.abs(tz);
    var tzHrs = Math.floor(tz / 60);
    var tzMin = tz % 60;
    K += ii(tzHrs) + ":" + ii(tzMin);
  }
  format = format.replace(/(^|[^\\])K/g, "$1" + K);

  var day = (utc ? date.getUTCDay() : date.getDay()) + 1;
  format = format.replace(new RegExp(dddd[0], "g"), dddd[day]);
  format = format.replace(new RegExp(ddd[0], "g"), ddd[day]);

  format = format.replace(new RegExp(MMMM[0], "g"), MMMM[M]);
  format = format.replace(new RegExp(MMM[0], "g"), MMM[M]);

  format = format.replace(/\\(.)/g, "$1");

  return format;
}

export function camelize(str) {
  return str
    .split(" ")
    .map(function (word, index) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
}

// TODO: Remove
export function companyPath() {
  return "EposCompanyFieldsDefinition";
}
