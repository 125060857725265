import { CurrentActionTypes } from "../constants/actionTypes";

export const setTitle = (title, subTitle) => {
  return {
    type: CurrentActionTypes.SET_CONFIGURATION,
    data: { title: title, subTitle: subTitle },
  };
};

export const setSelectedPOS = (pos) => {
  return {
    type: CurrentActionTypes.SET_SELECTED_POS,
    data: pos,
  };
};

export const setSelectedTemplateType = (templateType) => {
  return {
    type: CurrentActionTypes.SET_SELECTED_TEMPLATE_TYPE,
    data: templateType,
  };
};

export const setSelectedTemplate = (template) => {
  return {
    type: CurrentActionTypes.SET_SELECTED_TEMPLATE,
    data: template,
  };
};
