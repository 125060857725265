import React, { Component } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import Config from "../../../json/config.json";
import { DeleteTemplate, GetAllTemplates, SetDefault } from "../../lib/api/data/Request";
import {
  setSelectedTemplate,
  setSelectedTemplateType
} from "../../../redux/actions/CurrentActions";
import { setData, resetData } from "../../../redux/actions/TemplateActions";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faLightbulb, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import { camelize } from "../../lib/global/helpers";
import NewTemplate from "./newTemplate";

library.add(faLightbulb, faStar);

class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      isLoading: true,
      templateType: "",
      pageTitle: "",
      templates: [],
    };

    this._setDefault = this._setDefault.bind(this);
    this._deleteTemplate = this._deleteTemplate.bind(this);
    this._fetchData();
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    var templateType = this.props.match.params.type;

    templateType =
      typeof templateType !== "undefined" ? templateType : "invoice-template";
    templateType = templateType.replaceAll("-", "");
    //console.log(prevState.templateType, templateType);
    if (prevState.templateType !== templateType) {
      this._fetchData();
    }
  }

  _fetchData = () => {
    var templateType = this.props.match.params.type;
    templateType =
      typeof templateType !== "undefined" ? templateType : "invoice-template";
    var pageTitle = templateType.toString().replaceAll("-", " ");
    templateType = templateType.toString().replaceAll("-", "");

    this.props.setSelectedTemplateType(templateType);
    this.setState({
      templateType,
      pageTitle,
      templates: [],
    });
    this.setState({ isLoading: true }, function () {
      GetAllTemplates().then((response) => {
        if (response.isSuccess) {
          this.setState(
            { templates: response.data.Result, isLoading: false },
            function () {
              //console.log(this.state.templates);
            }
          );
        }
      });
    });
  };

  _closeEditor = (e) => {
    this._fetchData();
    this.setState({ showPopup: false });
  };

  _showEditor = (templateData) => {
    this.props.setSelectedTemplate(templateData);
    this.props.setData(JSON.parse(templateData.TemplateData));
    this.setState({ showPopup: true });
  };

  _setDefault = async(template, key) => {
    var _templates = Object.assign([], this.state.templates);
    var _template = Object.assign({}, template);

    _templates.forEach((t) => {
      t.IsDefault = false;
    });
    console.log(_templates);
    _template.IsDefault = true;
    _templates = [
      ..._templates.slice(0, key),
      _template,
      ..._templates.slice(key + 1),
    ];

    await this.props.setSelectedTemplate(template);

    SetDefault(_templates, _template).then((result) => {
      this.setState({
        templates: _templates,
      });
    });
  };

  _deleteTemplate = async(template, key) => {
    var _template = Object.assign({}, template);
    _template.IsDeleted=true
    console.log("_template===>>", _template)
    await DeleteTemplate(_template)
    this.setState({
      templates: [...this.state.templates].filter(t => t.ID!==template.ID)
    })
  }

  render() {
    return (
      <>
        <div className="list">
          <div className="list-header">
            <div className="list-filter d-flex">
              <h3>{camelize(this.state.pageTitle)}</h3>

              <div className="ms-auto d-flex">
                <Button
                  varient="primary"
                  onClick={(e) => {
                    this.props.setSelectedTemplate(null);
                    this.props.resetData();
                    this.setState({ showPopup: true });
                  }}
                >
                  New Template
                </Button>
                {/*
              <Button
                variant="link ml-2"
                className="text-dark text-decoration-none"
                onClick={(e) => {
                  this.props.showToolTips(true);
                }}
              >
                <FontAwesomeIcon icon="lightbulb" className="me-2" />
                Tool Tips
              </Button>
              */}
              </div>
            </div>
          </div>

          <div className="list-content">
            {this.state.isLoading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100%" }}
              >
                <Loader type="Rings" color="#CCCCCC" height={100} width={100} />
              </div>
            ) : (
              <></>
            )}
            <div className="scrollbox">
              <ul className="templates card-deck">
                {
                this.state.templates &&
                typeof (this.state.templates) === 'object' &&
                this.state.templates.length > 0 ? (
                this.state.templates.map((template, key) => {
                  return (
                    <li className="thumbnail-container mb-3" key={key}>
                      <div
                        className="thumbnail card mx-0 align-items-center thumbnail-max bg-light"
                        href={template.ID}
                      >
                        <div className="thumbnail-content">
                          <img src="/inc/img/printer.png" className="rounded" />
                        </div>
                        <p className="over-flow" title={template.TemplateName}>
                          {template.TemplateName}
                        </p>
                        <div className="caption card-img-overlay p-0 d-flex align-items-end">
                          <div className="card-body bg-dark text-center">
                            <Button
                              pathname={template.ID}
                              className="btn btn-primary me-2"
                              onClick={() => {
                                this._showEditor(template);
                              }}
                            >
                              Edit
                            </Button>
                            <div className="btn-group dropdown">
                              <Dropdown>
                                <Dropdown.Toggle variant="secondary">
                                  Settings
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {/*
                                //TODO: Finish functionality
                                <Dropdown.Item href="#/action-1">
                                  Preview
                                </Dropdown.Item>
                                */}
                                  <Dropdown.Item
                                    onClick={(e) => {
                                      this._setDefault(template, key);
                                    }}
                                  >
                                    Set As Default
                                  </Dropdown.Item>
                                  <Dropdown.Item 
                                    //href="#/action-1"
                                    onClick={(e) => {
                                      this._deleteTemplate(template, key);
                                    }}>
                                    Delete
                                  </Dropdown.Item>
                                  {
                                    //TODO: Finish functionality
                                    /*
                            <Dropdown.Header>Clone as</Dropdown.Header>
                            <Dropdown.Item href="#/action-3">
                              Payment Receipt
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                              Payment Receipt
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                              Payment Receipt
                            </Dropdown.Item>
                            */
                                  }
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                        {template.IsDefault ? (
                          <div
                            style={{
                              borderBottom: "32px solid rgb(65, 150, 255)",
                              bottom: "0",
                              borderRight: "32px solid transparent",
                              position: "absolute",
                              left: 0,
                            }}
                          >
                            <FontAwesomeIcon
                              style={{
                                position: "absolute",
                                top: "16px",
                                left: "3px",
                                fontSize: "11px",
                              }}
                              icon={"star"}
                              color="white"
                            />
                          </div>
                        ) : (
                          <> </>
                        )}
                      </div>
                    </li>
                  );
                })
                
                ):(<>
                
                {!this.state.isLoading ? (
              <>  Sorry no templates found for your organization, Please add a new template</>
                ):('')
                }
                </>)
              
                }
              </ul>
            </div>
          </div>
        </div>

        {this.state.showPopup ? (
          <NewTemplate
            {...this.props}
            callbackFromParents={this._closeEditor}
          />
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedTemplateType: state.current.selectedTemplateType,
    selectedTemplate: state.current.selectedTemplate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedTemplateType: (e) => dispatch(setSelectedTemplateType(e)),
    setSelectedTemplate: (template) => dispatch(setSelectedTemplate(template)),
    setData: (data) => dispatch(setData(data)),
    resetData: () => dispatch(resetData()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Invoice));
