import { TemplateActionTypes } from "../constants/actionTypes";

export const updateProp = (data) => {
  return {
    type: TemplateActionTypes.UPDATE_PROPS,
    data: data,
  };
};

export const updateHead = (data) => {
  return {
    type: TemplateActionTypes.UPDATE_HEAD,
    data: data,
  };
};

export const updateItems = (data) => {
  console.log("data",data)
  return {
    type: TemplateActionTypes.UPDATE_ITEMS,
    data: data,
  };
};

export const updateTotal = (data) => {
  return {
    type: TemplateActionTypes.UPDATE_TOTAL,
    data: data,
  };
};

export const updateFoot = (data) => {
  return {
    type: TemplateActionTypes.UPDATE_FOOT,
    data: data,
  };
};

export const setSelectedElement = (section, path, index, element) => {
  return {
    type: TemplateActionTypes.SET_SELECTED_ELEMENT,
    data: { section: section, path: path, index: index, element: element },
  };
};

export const moveElement = (top, left) => {
  return {
    type: TemplateActionTypes.MOVE_ELEMENT,
    data: { top: top, left: left },
  };
};

export const resizeElement = (height, width) => {
  return {
    type: TemplateActionTypes.RESIZE_ELEMENT,
    data: { height: height, width: width },
  };
};

export const setSectionHeight = (
  headHeight,
  bodyHeight,
  totalHeight,
  footerHeight
) => {
  return {
    type: TemplateActionTypes.SET_SECTION_HEIGHT,
    data: {
      head: headHeight,
      body: bodyHeight,
      total: totalHeight,
      foot: footerHeight,
    },
  };
};

export const updateLogo = (section, element) => {
  return {
    type: TemplateActionTypes.UPDATE_ELEMENT,
    data: { section: section, element: element },
  };
};

export const setData = (data) => {
  return {
    type: TemplateActionTypes.SET_DATA,
    data,
  };
};

export const resetData = () => {
  return {
    type: TemplateActionTypes.RESET_DATA,
    data: {},
  };
};

export const updateHeaderLogo = (data) => {
  return {
    type: TemplateActionTypes.UPDATE_LOGO,
    data,
  };
};

export const updateSplitSizes = (data) => {
  return {
    type: TemplateActionTypes.UPDATE_SPLIT_SIZES,
    data: data
  }
}

export const removeHeaderImage = () => {
  return {
    type: TemplateActionTypes.REMOVE_HEADER_IMAGE,
    data: {}
  }
}
