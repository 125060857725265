import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import Invoice from "./invoice";

class Index extends Component {
  constructor(props) {
    super(props);
    this.showEditorCallBack = this.showEditorCallBack.bind(this);
    this.state = {
      showPopup: false,
    };
  }

  showEditorCallBack = (e, templateType) => {
    this.setState({ showPopup: true });
  };

  render() {
    var templateType = this.props.match.params.type;

    return (
      <>
        {templateType ? (
          <Invoice
            {...this.props}
            callbackFromParents={this.showEditorCallBack}
          />
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedTemplateType: state.current.selectedTemplateType,
    selectedPOS: state.current.selectedPOS,
  };
};

const mapDispatchToProps = (dispatch) => {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Index));
