import React from 'react'

export default function HTMLFooterTemplate(props) {

    console.log("props.properties.prop.===>>", props.properties.prop)
    const pageSettings = {
        'a4': {
            'width': 794,
            'height': 1123
        },
        'letter': {
            'width': 595,
            'height': 842
        }
    };


    const renderElements = (section) => {
        return (
            props.properties &&
            Object.keys(props.properties[section].elements).map((key) => {
                var data = props.properties[section].elements[key];
                var font = props.properties[section]?.font || {fontFamily:'Arial', fontSize: 10, color:'#000000'}
                var dStyle = Object.assign({}, data.style);
                dStyle.color = props.properties.prop.fontColor;
                dStyle.fontSize = props.properties.prop.fontSize + "pt";
                dStyle.left=parseInt(dStyle.left)*0.75;
                dStyle.zIndex=2
                return (
                    <div
                        key={data.id}
                        style={Object.assign(dStyle, font)}
                        id={data.id}
                        data-element={data.name}
                        data-section={section}
                        data-path="elements"
                        data-index={data.name.replace(".", "")}
                    >
                        {data.text}
                    </div>
                );
            })
        );
    };

    const renderShapes = () => {
        return Object.keys(props.properties.foot.shapes).filter(s => s!=='border').map((key) => {
            var prop = props.properties.foot.shapes[key];
            // var dStyle = Object.assign({}, prop.style);
            var borderColor = props.properties.foot.shapes.border.color
            var borderSize = props.properties.foot.shapes.border.size
            var bgColor= props.properties.foot.shapes.border.fillColor
            var dStyle = Object.assign({minWidth:'32px', minHeight:'16px'}, prop.style);
            dStyle.border = `${borderSize}px solid ${borderColor}`;
            dStyle.background=`${bgColor}`
            dStyle.left=parseInt(dStyle.left)*0.75;
            dStyle.width=parseInt(dStyle.width)*0.75;
            dStyle.top=parseInt(dStyle.top)
            dStyle.zIndex=1
            return (
                <div
                    key={key}
                    style={dStyle}
                    id={prop.id}
                    data-element={prop.name}
                    data-section={"foot"}
                    data-path="shapes"
                    data-index={prop.data}
                >

                </div>
            );
        });

      };



    return (

        <div style={{ width: '100%' }} className="foot">
            <div style={{
                height: props.properties.prop.orientation==="landscape" ? props.heights.foot * 0.01 * pageSettings[props.properties.prop.paperSize].width : props.heights.foot * 0.01 * pageSettings[props.properties.prop.paperSize].height,
                position: 'relative',
                backgroundColor: props.properties.foot
                    .background.showColor
                    ? props.properties.foot.background.color
                    : "transparent",
            }}>
                <div
                >
                    {renderElements("foot")}
                    {renderShapes()}
                </div>
            </div>
        </div>


    )
}
