import React, { useState } from 'react';
import { GlobalActionTypes } from "../constants/actionTypes";

const initialState = {
  currentUser: { userID: 1 },
  cloudUser: { userID: sessionStorage.getItem('userID') },
  configuration: {
    orgID: sessionStorage.getItem('orgID'),
    cloudLocationID: sessionStorage.getItem('LocationID'),
    isSigned: sessionStorage.getItem('loggedin')
  },
};

export const globalReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case GlobalActionTypes.SET_CONFIGURATION:
      return {
        ...state,
        configutation: {
          ...state.configutation,
          orgID: data.orgID,
          cloudLocationID: data.cloudLocationID,
        },
      };

    case GlobalActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: data,
      };

    case GlobalActionTypes.SET_CLOUD_USER:
      return {
        ...state,
        cloudUser: data,
      };

    default:
      return state;
  }
};
