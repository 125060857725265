import React from 'react'

export default function HTMLTemplate(props) {
    const pageSettings = {
        'a4': {
            'width': 794,
            'height': 1123
        },
        'letter': {
            'width': 595,
            'height': 842
        }
    };

    const renderImage = (section) => {

        return props.properties.head.logo.showLogo &&
            props.properties.head.logo.logo !== null ? (
            <div
                style={props.properties.head.logo.style}
                key={props.properties.head.logo.id}
                data-element={props.properties.head.logo.name}
                data-section={section}
                data-path="logo"
                data-index=""
            >
                <div>
                    <img
                        src={props.tempLogo ? props.tempLogo : props.properties.head.logo.logo}
                        style={{
                            width:
                                (props.properties
                                    ? props.properties.head.logo.logoSize
                                    : 100) + "%",
                            pointerEvents: "none",
                        }}
                    />
                </div>
            </div>
        ) : (
            <></>
        );
    };

    const renderElements = (section) => {
        return (
            props.properties &&
            Object.keys(props.properties[section].elements).map((key) => {
                var data = props.properties[section].elements[key];
                var dStyle = Object.assign({}, data.style);
                dStyle.color = props.properties.prop.fontColor;
                dStyle.fontSize = props.properties.prop.fontSize + "pt";

                return (
                    <div
                        key={data.id}
                        style={dStyle}
                        id={data.id}
                        data-element={data.name}
                        data-section={section}
                        data-path="elements"
                        data-index={data.name.replace(".", "")}
                    >
                        {data.text}
                    </div>
                );
            })
        );
    };

    const renderVatSummary = () => {
        if( props.properties && props.properties.total && props.properties.total.VatSummary && Object.keys(props.properties.total.VatSummary).length === 0 && props.properties.total.VatSummary.constructor === Object) {
            var data = props.properties.total.VatSummary;
            var dStyle = Object.assign({}, data.style);
            dStyle.color = props.properties.prop.fontColor;
            dStyle.fontSize = props.properties.prop.fontSize + "pt";
            return (

                <div
                    key={data.id}
                    style={dStyle}
                    id={data.id}
                    data-element={data.name}
                    data-section="VatSummary"
                    data-path="elements"
                    data-index={data.name?data.name.replace(".", ""):data.id}
                >
                    {data.text}
                </div>
            )
        }
    }

    const renderShapes = (section) => {
        return Object.keys(props.properties[section].shapes).filter(s => s!=='border').map((key) => {
            var prop = props.properties[section].shapes[key];
            // var dStyle = Object.assign({}, prop.style);
            var borderColor = props.properties[section].shapes.border.color
            var borderSize = props.properties[section].shapes.border.size
            var bgColor= props.properties[section].shapes.border.fillColor
            var dStyle = Object.assign({minWidth:'32px', minHeight:'16px'}, prop.style);
            dStyle.border = `${borderSize}px solid ${borderColor}`;
            dStyle.background=`${bgColor}`
            dStyle.left=parseInt(dStyle.left)*0.75;
            dStyle.width=parseInt(dStyle.width)*0.75;
            dStyle.top=parseInt(dStyle.top)
            return (
                <div
                    key={key}
                    style={dStyle}
                    id={prop.id}
                    data-element={prop.name}
                    data-section={section}
                    data-path="shapes"
                    data-index={prop.data}
                >

                </div>
            );
        });

      };


    const renderCompanyElements = () => {
        return Object.keys(props.properties.head.companyProps).map((key) => {
            var prop = props.properties.head.companyProps[key];
            var dStyle = Object.assign({}, prop.style);
            dStyle.color = props.properties.prop.fontColor;
            dStyle.fontSize = props.properties.prop.fontSize + "pt";
            if (!prop.show) return false;
            return (
                <div
                    onClick={(e) => this._onElementClick(e)}
                    key={key}
                    style={dStyle}
                    id={prop.id}
                    data-element={prop.name}
                    data-section={"head"}
                    data-path="companyProps"
                    data-index={prop.data}
                >
                    {prop.text}
                </div>
            );
        });
    };

    const renderDocumentTitle = () => {
        var title = props.properties.head.documentTitle;

        var dStyle = Object.assign({}, title.style);
        dStyle.color = props.properties.prop.fontColor;
        dStyle.fontSize = "20pt";
        if (!title.show) return false;

        return props.properties &&
            props.properties.head.documentTitle.show ? (
            <div
                style={dStyle}
                key="{key}"
                id={title.id}
                data-element={title.name}
                data-section={"head"}
                data-path="documentTitle"
                data-index={title.title}
            >
                {title.title}
            </div>
        ) : (
            <></>
        );
    };

    const renderBodyElements = (displayData) => {
        // const totalWidth = Object.keys(displayData).map(t => displayData[t])
        // .reduce((a, b) => {
        //   return  a + Number(b.width?b.width:10); // returns object with property x
        // }, 0)
        // console.log("totalWidth===>", totalWidth)
        return (
            <table
                style={{
                    width: "100%",
                }}
                cellSpacing={0}
                className="items-table"
            >
                <thead>
                    <tr>


                        {
                            displayData.map((obj, key) => {
                                // var obj = displayData[key];

                                if (displayData[key]) {
                                    return (
                                        <th
                                            width={obj.width ? (obj.width / (displayData.reduce((a, v) => { return (a + Number(v.width ? v.width : 10)) }, 0)) * 100 + "%") : 'auto'}
                                            style={{
                                                padding: "10px 0px 10px 10px",
                                                textAlign: "left",
                                                backgroundColor:
                                                    props.properties.items.layout.tableHeader
                                                        .backgroundColor,
                                                fontSize:
                                                    props.properties.items.layout.tableHeader.fontSize +
                                                    "pt",
                                                fontFamily:
                                                    props.properties.items.layout.tableHeader.fontFamily,
                                                color:
                                                    props.properties.items.layout.tableHeader.fontColor,
                                            }}
                                            key={`dd_${key}`}
                                        >
                                            {obj.stringFormat ? obj.stringFormat : obj.header}
                                        </th>
                                    );
                                }


                            })
                        }

                    </tr>
                </thead>
                {'{TableBodyContent}'}
            </table>
        );
    }
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: props.properties.prop.orientation === "landscape" ? '1132px' : '800px',
            minHeight: '400px',
            backgroundColor: props.properties.prop
                .background.showColor
                ? props.properties.prop.background.color
                : "transparent",
        }}>
            <div>
                <table style={{
                    width: '100%',
                    backgroundColor: props.properties.head.background.showColor
                        ? props.properties.head.background.color
                        : "transparent",
                }}>
                    <tr>
                        {/* <td>
                            <div style={{ height: props.heights.head * 0.01 * pageSettings[props.properties.prop.paperSize].height }}>
                                <div
                                // style={{
                                //     position: "absolute",
                                // }}
                                >
                                    {renderElements("head")}
                                    {renderImage("head")}
                                    {renderDocumentTitle()}
                                    {renderCompanyElements()}
                                </div>
                            </div>

                        </td> */}
                    </tr>
                    <tr>
                        <td>
                            <div style={{
                                // maxHeight:props.heights.body*0.01*pageSettings[props.properties.prop.paperSize].height
                            }}>
                                <div id="items_table_conteiner">
                                    {renderBodyElements(Object.keys(props.properties.items.items).map(t => props.properties.items.items[t]).filter(d => d && d.width !== undefined))}
                                </div>
                            </div>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{
                                    height: props.heights.total * 0.01 * pageSettings[props.properties.prop.paperSize].height,
                                    position: 'relative',
                                    backgroundColor: props.properties.total
                                        .background.showColor
                                        ? props.properties.total.background.color
                                        : "transparent",
                                }}
                                className='total-section'
                            >
                                <div>
                                    {renderElements("total")}
                                    {renderVatSummary()}
                                    {renderShapes("total")}
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            {/* <div style={{ width: '100%' }} className="foot">
                <div style={{ 
                    height: props.heights.foot * 0.01 * pageSettings[props.properties.prop.paperSize].height, 
                    position: 'relative',
                    backgroundColor: props.properties.foot
                                      .background.showColor
                                      ? props.properties.foot.background.color
                                      : "transparent",
                }}>
                    <div
                    >
                        {renderElements("foot")}
                    </div>
                </div>
            </div> */}


        </div>
    )
}
